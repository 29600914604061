import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

export const name = 'platformDSCommunicationAspect'
export const defaultModel = {
    notifyApplicationRequestCallbacks: [],
    platformContextCounter: 0,
    platformInitAndStartContextCounter: 1,
    platformLoadContextCounter: 1,
    livePreviewAppsToRefresh: [],
    livePreviewControllersToRefresh: [],
    extraOptionsForInitMessage: {
        shouldFetchData: null
    }
}

export const functionLibrary = {
    registerToNotifyApplicationRequestFromViewerWorker: withActions(({setNotifyApplicationRequestCb}, getNotifyApplicationRequestCallbacksArr, cb) => {
        setNotifyApplicationRequestCb(getNotifyApplicationRequestCallbacksArr().length, 0, cb)
    }),
    triggerNotifyApplicationRequest: (getNotifyApplicationRequestCallbacksArr, appDefinitionId, data) => {
        getNotifyApplicationRequestCallbacksArr().forEach(cb => cb(appDefinitionId, data))
    },
    getNewContextCounter: currentCounter => {
        currentCounter = currentCounter || 0
        return currentCounter + 1
    }
}
