import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

const name = 'CommonConfigAspect'

export const defaultModel = {
    commonConfigRegisteredComps: {}
}

export function init({}, {initialData: {
    bsiInstanceWrapper,
    setCommonConfigValue
}}) {
    if (bsiInstanceWrapper) {
        bsiInstanceWrapper.registerToBsiChanges(newBsi => {
            setCommonConfigValue('bsi', newBsi)
        })
    }
}

const functionLibrary = {
    registerToCommonConfigUpdated: withActions((aspectActions, compId, comp) => {
        aspectActions.setCommonConfigRegisteredComp(compId, comp)
    }),
    unRegisterToCommonConfigUpdated: withActions((aspectActions, compId) => {
        aspectActions.setCommonConfigRegisteredComp(compId, undefined)
    }),
    commonConfigUpdated: (newCommonConfig, registeredComps) => {
        if (!_.isEmpty(newCommonConfig)) {
            _.forEach(registeredComps, comp => {
                if (_.isFunction(comp.sendPostMessage)) {
                    comp.sendPostMessage({
                        intent: 'addEventListener',
                        eventType: 'COMMON_CONFIG_UPDATE',
                        params: newCommonConfig
                    })
                }
            })        
        }
    }
}

export {name, functionLibrary}
