'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const createReactClass = require('create-react-class')
const {FontRuler} = require('./FontRuler')

const fontRuler = React.createElement.bind(null, FontRuler)
fontRuler.type = FontRuler

const FontRulersContainer = createReactClass({
    displayName: 'FontRulersContainer',
    propTypes: {
        fontsList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
        updateFontLoaded: PropTypes.func.isRequired
    },

    render() {
        if (!this.props.fontsList.length) {
            return null
        }

        const fontRulersContainerStyle = {
            overflow: 'hidden',
            visibility: 'hidden',
            maxHeight: 0,
            maxWidth: 0,
            position: 'absolute'
        }
        const fontRulerContentAfter = React.createElement('style', {
            key: 'fontRulerContentAfter',
            dangerouslySetInnerHTML: {__html: '.font-ruler-content::after {content:"@#$%%^&*~IAO"}'}
        })
        const fontRulers = _.map(this.props.fontsList, fontFamily => fontRuler({
            key: fontFamily,
            fontFamily,
            onLoadCallback: this.props.updateFontLoaded
        }))

        return React.createElement('div', {className: 'font-ruler-container', style: fontRulersContainerStyle}, [fontRulerContentAfter, ...fontRulers])
    }
})

module.exports = {
    FontRulersContainer
}
