/* eslint-disable react/no-find-dom-node, react/no-string-refs */
'use strict'

const _ = require('lodash')
const React = require('react')
const ReactDOM = require('react-dom')
const PropTypes = require('prop-types')
const createReactClass = require('create-react-class')
const {fonts, animationFrame} = require('santa-core-utils')

/**
* Adopted from:
* "Web font loading detection, without timers"
* [http://smnh.me/web-font-loading-detection-without-timers/]
* A blog post by Simon Hanukaev [https://github.com/smnh]
*/

function hasArea(elem) {
    return elem && elem.offsetWidth && elem.offsetHeight
}

function areaChanged(area1, area2) {
    return area1.offsetWidth !== area2.offsetWidth || area1.offsetHeight !== area2.offsetHeight
}

function cloneArea(elem) {
    return elem && {
        offsetWidth: elem.offsetWidth,
        offsetHeight: elem.offsetHeight
    }
}

function handleFontResize() { // eslint-disable-line complexity
    if (!this._isMounted) {
        return
    }

    if (this.triggeredResize) {
        return
    }

    let trigger = this.trigger
    if (!trigger) {
        trigger = hasArea(this.contentNodeOrigSize)
        if (trigger) {
            const contentNode = ReactDOM.findDOMNode(this.refs.content)
            if (!contentNode) {
                return
            }
            trigger = areaChanged(contentNode, this.contentNodeOrigSize)
        }
    }

    if (trigger) {
        if (this.rafID) {
            animationFrame.cancel(this.rafID)
            this.rafID = 0
        }
        this.triggeredResize = true
        this.props.onLoadCallback(this.props.fontFamily)
    } else if (!this.rafID) {
        this.rafID = animationFrame.request(this.updateCurrentSize)
    }
}

const FontRuler = createReactClass({
    displayName: 'FontRuler',

    propTypes: {
        fontFamily: PropTypes.string.isRequired,
        onLoadCallback: PropTypes.func.isRequired
    },

    updateCurrentSize() {
        this.rafID = 0

        const wrapperNode = ReactDOM.findDOMNode(this.refs.wrapper)
        if (!wrapperNode) {
            return
        }
        const contentNode = ReactDOM.findDOMNode(this.refs.content)
        const innerContentNode = ReactDOM.findDOMNode(this.refs.innerContent)
        const innerWrapperNode = ReactDOM.findDOMNode(this.refs.innerWrapper)

        const origSize = cloneArea(wrapperNode)
        const fontFamily = this.getUploadFontFamilyShortName()

        // Resize wrapper and scroll its content to the bottom right corner
        wrapperNode.style.width = `${origSize.offsetWidth - 1}px`
        wrapperNode.style.height = `${origSize.offsetHeight - 1}px`
        wrapperNode.scrollLeft = wrapperNode.scrollWidth - wrapperNode.clientWidth
        wrapperNode.scrollTop = wrapperNode.scrollHeight - wrapperNode.clientHeight

        // Resize inner content and scroll inner wrapper to the bottom right corner
        innerContentNode.style.width = `${origSize.offsetWidth + 1}px`
        innerContentNode.style.height = `${origSize.offsetHeight + 1}px`
        innerWrapperNode.scrollLeft = innerWrapperNode.scrollWidth - innerWrapperNode.clientWidth
        innerWrapperNode.scrollTop = innerWrapperNode.scrollHeight - innerWrapperNode.clientHeight

        if (hasArea(contentNode)) {
            if (this.contentNodeOrigSize) {
                this.trigger = areaChanged(contentNode, this.contentNodeOrigSize)
            } else {
                this.contentNodeOrigSize = cloneArea(contentNode)
            }
            contentNode.style.fontFamily = `${fontFamily}, serif`
        }
    },

    getUploadFontFamilyShortName() {
        const fontFamily = this.props.fontFamily
        return fonts.isUploadedFontFamily(fontFamily) ?
            fonts.getShortUploadedFontFamily(fontFamily) :
            fontFamily
    },

    handleScroll(evt) {
        evt.stopPropagation()
        evt.preventDefault()
        this.handleFontResizeDebounced()
    },

    componentWillMount() {
        this.handleFontResizeDebounced = _.debounce(handleFontResize.bind(this), 32, {
            leading: false,
            trailing: true
        })
    },

    componentDidMount() {
        this._isMounted = true
        this.trigger = false
        this.triggeredResize = false
        this.refs.wrapper.addEventListener('scroll', this.handleScroll, true)
        this.refs.innerWrapper.addEventListener('scroll', this.handleScroll, true)
        this.updateCurrentSize()
    },

    componentWillUnmount() {
        this._isMounted = false
        if (this.rafID) {
            animationFrame.cancel(this.rafID)
            this.rafID = 0
        }
        this.refs.wrapper.removeEventListener('scroll', this.handleScroll)
        this.refs.innerWrapper.removeEventListener('scroll', this.handleScroll)
    },

    render() {
        const innerContent = React.createElement('div', {
            ref: 'innerContent'
        })
        const innerWrapper = React.createElement('div', {
            ref: 'innerWrapper',
            style: {
                position: 'absolute',
                width: '100%',
                height: '100%',
                overflow: 'hidden'
            }
        }, innerContent)
        const content = React.createElement('div', {
            style: {
                position: 'relative',
                whiteSpace: 'nowrap',
                fontFamily: 'serif'
            }
        }, innerWrapper, React.createElement('span', {ref: 'content', className: 'font-ruler-content'}) /* characters that are likely to change in size between fonts */)
        const wrapper = React.createElement('div', {
            ref: 'wrapper',
            style: {
                position: 'absolute',
                overflow: 'hidden',
                fontSize: 1200,
                left: -2000,
                visibility: 'hidden'
            }
        }, content)
        return wrapper
    }
})

module.exports = {
    FontRuler
}
