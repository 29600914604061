import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'
import {getScrollDirection} from 'carmi-host-extensions/src/aspects/windowScroll/windowScroll'

const name = 'PopupPageAspect'

const defaultModel = {
    registeredCloseHandlers: {},
    popupIdToRender: null,
    scrollPosition: {x: 0, y: 0},
    scrollDirection: '',
    scrollListeners: {}
}

const functionLibrary = {
    openPopupPage: withActions((actions, behavior, getRootNavInfo, navigateToPageRef) => {
        const delay = _.get(behavior, ['params', 'delay'], 0) * 1000
        setTimeout(() => {
            const rootNavInfo = getRootNavInfo() || {}
            if (behavior.compId === 'masterPage' || behavior.compId === rootNavInfo.pageId) {
                actions.setPopupOpenedByPageLoad(behavior.targetId, true)
                navigateToPageRef()
            }
        }, delay)
    }),
    registerCloseHandler: withActions((aspectActions, popupId, closeHandler) => {
        aspectActions.setCloseHandler(popupId, closeHandler)
    }),
    closePopupPageIfOpen: withActions((aspectActions, currentPopupId, navigateToPageRef, closeHandler, extraData) => {
        if (!currentPopupId) {
            return
        }
        navigateToPageRef()
        if (closeHandler) {
            closeHandler(extraData)
            aspectActions.setCloseHandler(currentPopupId, undefined)
        }
    }),

    updatePopupToRender: withActions((actions, popupId, shouldSetPopupToRender, runNavigationCallbacks) => {
        if (popupId && shouldSetPopupToRender) {
            actions.setPopupIdToRender(popupId)
            runNavigationCallbacks()
        }
        if (!popupId) {
            actions.setPopupIdToRender(null)
            runNavigationCallbacks()
        }
    }),

    registerToPopupScroll: withActions(({setScrollListener}, {props: {id}, onScroll}) => {
        setScrollListener(id, onScroll)
    }),

    unregisterToPopupScroll: withActions(({setScrollListener}, {props: {id}}) => {
        setScrollListener(id, undefined)
    }),

    propagatePopupScrollEvent: withActions(({setScrollPosition, setScrollDirection}, {prevPosition, prevDirection, listeners, getCompRefs}, newPosition) => {
        const newDirection = getScrollDirection(newPosition, prevPosition, prevDirection)
        setScrollPosition(newPosition)
        setScrollDirection(newDirection)
        _.forEach(listeners, (onScroll, compId) => onScroll.call(getCompRefs()[compId], newPosition, newDirection))
    }),

    cleanPopupOpenedByPageLoad: withActions(({setAllPopupOpenedByPageLoad}) => {
        setAllPopupOpenedByPageLoad({})
    })
}

const init = ({}, {eventsManager, initialData: {propagatePopupScrollEvent}}) => {
    eventsManager.on('popupScroll', event => {
        const popup = event.target
        const isScrollable = popup.scrollHeight > popup.offsetHeight
        propagatePopupScrollEvent({
            x: popup.scrollLeft,
            y: popup.scrollTop,
            progressY: isScrollable ? popup.scrollTop / (popup.scrollHeight - popup.offsetHeight - 1) : 0
        })
    })
}

export {
    name,
    defaultModel,
    init,
    functionLibrary
}
