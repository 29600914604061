export const getRunUserFunctionMessage = (contextPageId, params, compId, event) => ({
    intent: 'WIX_CODE',
    type: 'wix_code_run_user_function',
    contextId: contextPageId,
    callbackId: params.callbackId,
    compId,
    event
})

export const getNativeTpaEventMessage = (contextPageId, callbackId, compId, eventArgs) => ({
    intent: 'TPA_NATIVE_EVENT',
    contextId: contextPageId,
    callbackId,
    compId,
    eventArgs
})

export const getTriggerAppStudioWidgetOnPropsChangedMessage = ({contextId, oldProps, newProps}) => ({
    intent: 'WIX_CODE',
    contextId,
    type: 'trigger_app_studio_widget_on_props_changed',
    oldProps,
    newProps
})

export const getWorkerResetQueueToWaitForLoadMessage = ({contextId}) => ({
    intent: 'UPDATE_QUEUE',
    contextId,
    type: 'reset_editor_worker_queue_to_wait_for_load'
})

export const getTimelineEventMessage = (contextId, type) => ({
    intent: 'WIX_CODE_ANIMATE',
    type,
    contextId
})
