import _ from 'lodash'

const toggleUserLike = postId => {
    const likedPosts = JSON.parse(window.localStorage.getItem('likedBlogPosts')) || {}
    likedPosts[postId] = likedPosts[postId] === false || !_.has(likedPosts, postId)
    window.localStorage.setItem('likedBlogPosts', JSON.stringify(likedPosts))
}

const updateCounter = (counterType, counterName, counterValue, storeId, postId, fetchFn) => {
    if (counterType === 'likes') {
        toggleUserLike(postId)
    }
    let counterNameToReport = counterName
    if (!_.isNil(counterNameToReport)) {
        counterNameToReport = counterNameToReport.substring(0, 1400)
    }

    const sendObject = {
        storeId: storeId.datastoreId,
        postId,
        counterType,
        counterName: counterNameToReport,
        counterValue
    }

    const url = 'https://www.wix.com/_api/blog-social-counters/collector/'
    const fetchOptions = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(sendObject),
        headers: {'Content-Type': 'application/json'}
    }
    fetchFn(url, fetchOptions, 'json', _.noop, _.noop)
}

export {
    updateCounter
}
