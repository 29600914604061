'use strict'
const _ = require('lodash')
const {withActions} = require('carmi-host-extensions')

const handleAction = (combinedBehaviorsForComponentAction, handleBehavior, compId, action, event) => {
    const behaviors = _.get(combinedBehaviorsForComponentAction, [action.sourceId, action.name], [])

    behaviors.forEach(behavior => handleBehavior({...behavior, action, compId, event}))
}

const generateParamArray = (behaviorDef, behavior) => {
    const paramArr = _.map(behaviorDef.params, paramDef =>
        _.isString(paramDef) ?
            behavior.params[paramDef] :
            _.get(behavior.params, paramDef.name, paramDef.defaultValue)
    )

    return behavior.callback ? paramArr.concat(behavior.callback) : paramArr
}

const executeCompBehavior = (compRef, compClass, behaviors, removeBehavior) => {
    _.forOwn(behaviors, (behavior, behaviorId) => {
        const behaviorDef = _.get(compClass, ['behaviors', behavior.name])
        const methodName = behaviorDef && behaviorDef.methodName
        if (methodName && _.isFunction(compRef[methodName])) {
            const params = generateParamArray(behaviorDef, behavior)
            compRef[methodName].apply(compRef, params)
            removeBehavior(behaviorId)
        }
    })
}

const invokeBehaviorListener = (listener, behaviors, removeBehavior) => {
    //don't pass behaviors by ref because we delete them in the next line
    listener(_.clone(behaviors))
    _.forEach(behaviors, (behavior, behaviorId) => removeBehavior(behaviorId))
}

const trackBehaviorsToExecute = withActions(({registerBehaviorToExecuteListener}, compId, listener) => {
    registerBehaviorToExecuteListener(compId, listener)

    const callbackToUnregisterListener = () => {
        registerBehaviorToExecuteListener(compId)
    }

    return callbackToUnregisterListener
})

module.exports = {
    name: 'BehaviorsAspect',

    defaultModel: {
        behaviorsToExecute: {},
        behaviorsToExecuteListeners: {}
    },

    functionLibrary: {
        handleAction,
        executeCompBehavior,
        invokeBehaviorListener,
        trackBehaviorsToExecute,
        registerBehavior: (registerBehaviorToExecute, behavior) => {
            const behaviorId = _.uniqueId('behavior-')
            registerBehaviorToExecute(behaviorId, behavior)
        }
    }
}
