/* eslint-disable react/no-find-dom-node */
import ReactDOM from 'react-dom'
import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const name = 'QaAspect'

function deepCloneProps(depth, value, key) {
    if (_.isFunction(value) || _.startsWith(key, '_')) {
        return {}
    }
    if (depth > 10) {
        return ''
    }
    if (_.isArray(value)) {
        return _.map(value, val => deepCloneProps(depth + 1, val))
    }
    if (_.isPlainObject(value)) {
        return _.mapValues(value, (val, k) => deepCloneProps(depth + 1, val, k))
    }
    return value
}

const getComponentsByDisplayNameAndProps = ({compProps}, {compClasses, structure, compRefs}, container, displayName = '', props = {}) => {
    const rootElement = container instanceof Element ? container : ReactDOM.findDOMNode(container)
    displayName = displayName.toLowerCase()
    const comps = _.pickBy(compClasses, compClass => (compClass.baseDisplayName || compClass.displayName).toLowerCase() === displayName)
    if (!_.isEmpty(comps)) {
        return _(compProps)
            .pickBy((currentProps, compId) => {
                const isPropsEquals = _.isEqual(_.pick(currentProps, _.keys(props)), props)
                const isDescendantOfContainer = rootElement.querySelector(`#${compId}`)
                const isRelevantCompType = _.has(comps, structure[compId].componentType) 
                return isDescendantOfContainer && isPropsEquals && isRelevantCompType
            })
            .mapValues((currentProps, compId) => compRefs[compId])
            .values()
            .value()
    }
    const tagName = displayName
    const descendants = Array.from(rootElement.querySelectorAll(tagName))
    return _.filter(descendants, elm => _.every(props, (propValue, propName) => {
        if (propName === 'children') {
            return propValue === elm.innerHTML
        }
        propName = propName === 'className' ? 'class' : propName
        const elmAttrVal = elm.getAttribute(propName)
        return elmAttrVal === propValue
    }))
}

const getCompIdByNickname = (nicknameToCompId, nickname) => {
    const compId = _.get(nicknameToCompId, nickname)

    if (!compId) {
        throw new Error(`No component with nickname: ${nickname}`)
    } else {
        return compId
    }
}

const getCompNicknameById = (compIdToNickname, compId) => {
    const nickname = _.get(compIdToNickname, compId)

    if (!nickname) {
        throw new Error(`No component with id: ${compId}`)
    } else {
        return nickname
    }
}

const getComponentPropsByHtmlElement = ({compProps}, wrapperHtmlElement) => {
    const {id} = wrapperHtmlElement
    if (compProps[id]) {
        return JSON.stringify(deepCloneProps(0, compProps[id]))
    }

    throw new Error(`You are trying to get props of a DOM component (${wrapperHtmlElement.outerHTML}). ` +
    'use the node HTML attributes instead, or get props from the React component container')
}

const getElementsByDisplayNameAndProps = ({compProps}, {compClasses, structure, compRefs}, container, displayName = '', props = {}) => {
    const comps = getComponentsByDisplayNameAndProps({compProps}, {compClasses, structure, compRefs}, container, displayName, props)
    return _.map(comps, comp => ReactDOM.findDOMNode(comp))
}

export const functionLibrary = {
    getComponentsByDisplayNameAndProps: withActions(getComponentsByDisplayNameAndProps),
    getElementsByDisplayNameAndProps: withActions(getElementsByDisplayNameAndProps),
    getComponentPropsByHtmlElement: withActions(getComponentPropsByHtmlElement),
    getComponentByPageAndCompId: (compRefs, id) => compRefs[id],
    getCompIdByNickname,
    getCompNicknameById,
    isWixAdsAllowed(hideComponentsList = '') {
        return !hideComponentsList.includes('WixAds')
    },
    setQaAPI: (windowObject, qaApi, domSelectors) => {
        _.assign(windowObject, {
            qaApi,
            testApi: {domSelectors}
        })
    }
}
