'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')

const shouldComponentUpdateHOC = Comp =>
    class ShouldComponentUpdate extends React.Component {
        static propTypes = {
            shouldComponentUpdate: PropTypes.bool.isRequired
        }

        shouldComponentUpdate(nextProps) {
            return nextProps.shouldComponentUpdate
        }

        render() {
            const compProps = _.omit(this.props, ['shouldComponentUpdate'])
            return <Comp {...compProps} />
        }
    }

module.exports = shouldComponentUpdateHOC
