import {EXTERNAL_IDENTIFIERS} from '@wix/advanced-seo-utils/renderer'

export const OVERRIDE_IDENTIFIERS = {
    ...EXTERNAL_IDENTIFIERS,
    META_TAGS: 'META_TAGS',
    LINKS: 'LINKS'
}

export const HEAD_OPTION_IDENTIFIERS = {
    title: {
        type: String.name,
        identifier: OVERRIDE_IDENTIFIERS.TITLE
    },
    metaTags: {
        type: Array.name,
        identifier: OVERRIDE_IDENTIFIERS.META_TAGS
    },
    links: {
        type: Array.name,
        identifier: OVERRIDE_IDENTIFIERS.LINKS
    },
    structuredData: {
        type: Array.name,
        identifier: OVERRIDE_IDENTIFIERS.STRUCTURED_DATA
    }
}