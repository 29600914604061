'use strict'
const rendererHOC = require('../../components/RendererHOC')
const {withActions} = require('../../utils/withActions')
const _ = require('lodash')
const name = 'ComponentsRenderAspect'

const defaultModel = {
    compRefs: {},
    compRefsArrays: {}
}

const functionLibrary = {
    wrapWithRendererHOC: component => rendererHOC(component),
    handleComponentRender: (compId, registerLayoutPendingForComp) => registerLayoutPendingForComp(compId),
    flushCompRefs: withActions(({setCompRef, $runInBatch}, compRefsArrays) => {
        $runInBatch(() => {
            _.forOwn(compRefsArrays, (arr, id) => {
                setCompRef(id, _.last(arr))
            })
        })
    }),
    updateCompRef: withActions((aspectActions, id, element) => {
        if (element === null) {
            aspectActions.spliceCompRefArray(id, 0, 1)
        } else {
            aspectActions.pushCompRefArray(id, element)
        }
    })
}

module.exports = {
    name,
    defaultModel,
    functionLibrary
}
