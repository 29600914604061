'use strict'
const {aspectNames} = require('common-types')

const {FontsRulerAspect} = aspectNames

function init(/*actions, {eventsManager}*/) {}

module.exports = {
    functionLibrary: {},
    init,
    name: FontsRulerAspect
}
