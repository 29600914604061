import * as SiteButton from './aspects/siteButton/siteButton'
import * as MediaContainer from './aspects/mediaContainer/mediaContainer'
import * as MediaPlayer from './aspects/mediaPlayer/mediaPlayer'
import * as PinterestWidget from './aspects/pinterestWidget/pinterestWidget'
import * as InlinePopup from './aspects/inlinePopup/inlinePopup'
import * as HtmlComponent from './aspects/htmlComponent/htmlComponent'
import * as VkShare from './aspects/vkShare/vkShare'
import * as SlideShow from './aspects/slideShow/slideshow'
import * as QuickActionBar from './aspects/quickActionBar/quickActionBar'
import * as SliderGallery from './aspects/sliderGallery/sliderGallery'
import * as Reapter from './aspects/repeater/repeater'
import * as RepeatersTemplates from './aspects/repeater/repeatersTemplates'
import * as ModesTransitionGroup from './aspects/modesTransitionGroup/modesTransitionGroup'
import * as Popover from './aspects/popover/popover'

export const runtime = {
    PinterestWidget,
    InlinePopup,
    SiteButton,
    HtmlComponent,
    VkShare,
    MediaContainer,
    MediaPlayer,
    Reapter,
    RepeatersTemplates,
    SlideShow,
    QuickActionBar,
    SliderGallery,
    ModesTransitionGroup,
    Popover
}
