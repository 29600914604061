import _ from 'lodash'
import $ from 'zepto'
import {objectUtils} from 'santa-core-utils'
import {withActions} from 'carmi-host-extensions'
import {queryBlogCategoryPostCounts} from '../../utils/blog/blogCategories'
import {mergeBatchableListsRequestsIfAny} from './dataRequirementChecker/batchableListsRequestsMerger'
import {
    getDescriptorRequest,
    getRequestsForAppPart,
    getRequestsForBlogCategories,
    getVideoThumbRequests
} from './dataRequirementChecker/appPartDataRequirementsChecker'
import {reportFirstTimeRenderFinish, reportFirstTimeRenderStart} from '../../utils/wixappsLogger'
import {handleShareRequest} from '../../utils/blog/socialShareHandler'
import * as constants from 'santa-renderer/src/utils/constants'
const {DATA} = constants.pointers.data.DATA_MAPS

export const name = 'AppPartAspect'

export const defaultModel = {
    failedRequests: {},
    warmup: {},
    metadata: {
        descriptor: {},
        items: {},
        isCategoriesLoaded: false,
        videoThumbnails: {}
    }
}

const SEO_SOURCE = 'TPA'

const cloneWixappsStore = wixapps =>
    _.reduce(wixapps, (acc, {descriptor, ...packageStore}, packageName) => ({
        ...acc,
        [packageName]: {
            ...descriptor && {descriptor},
            ...objectUtils.cloneDeep(packageStore)
        }
    }), {})

const getRequests = ({getAppPartDrcAPI, compInfos, urlData, getRequestsFn}) => {
    const appPartDrcAPI = getAppPartDrcAPI()
    const requests = _.reduce(compInfos, (compsRequests, compInfo) => {
        const compRequests = getRequestsFn(appPartDrcAPI, compInfo, urlData)
        return compsRequests.concat(compRequests)
    }, [])
    return mergeBatchableListsRequestsIfAny(appPartDrcAPI, requests)
}

export const functionLibrary = {
    handleShareRequest,
    getItemsRequests: (getAppPartDrcAPI, compInfos, urlData) => getRequests({getAppPartDrcAPI, compInfos, urlData, getRequestsFn: getRequestsForAppPart}),
    getDescriptorRequest,
    getVideoThumbnailsRequests: (getAppPartDrcAPI, compInfos, packageName) => {
        const appPartDrcAPI = getAppPartDrcAPI()
        return _.reduce(compInfos, (compsRequests, compInfo) => {
            const compRequests = getVideoThumbRequests(appPartDrcAPI, packageName, compInfo.data.id)
            return compsRequests.concat(compRequests)
        }, [])
    },
    getBlogCategoriesPostCountRequests: (getAppPartDrcAPI, compInfos) => getRequests({getAppPartDrcAPI, compInfos, getRequestsFn: queryBlogCategoryPostCounts}),
    getBlogCategoriesRequests: (getAppPartDrcAPI, compInfos, isWaitingForBlogCategories) => {
        const appPartDrcAPI = getAppPartDrcAPI()
        if (_.isEmpty(compInfos) || !isWaitingForBlogCategories) {
            return []
        }
        const compInfosArr = _.values(compInfos)
        return getRequestsForBlogCategories(appPartDrcAPI, compInfosArr[0])
    },
    updatePageTitleAndMetaTags: withActions((
        {updateDataItem},
        {setMetaTags, currentPageData, setRuntimeSchema, setRunTimePageTitle, setRunTimePageDescription, getCurrentPageId},
        pageTitle,
        metaDescription,
        pageTitleSEO,
        metaKeywords,
        ogTags = [],
        jsonld,
        twitterMetaTags = []
    ) => {
        updateDataItem(DATA, getCurrentPageId(), _.defaults({
            metaKeywordsSEO: metaKeywords,
            title: pageTitle,
            descriptionSEO: metaDescription,
            pageTitleSEO
        }, currentPageData))

        setMetaTags({value: [...ogTags, ...twitterMetaTags], source: SEO_SOURCE})
        setRuntimeSchema({value: jsonld, source: SEO_SOURCE})
        setRunTimePageTitle({value: pageTitle, source: SEO_SOURCE})
        setRunTimePageDescription({value: metaDescription, source: SEO_SOURCE})
    }),
    wixappsReportFirstTimeRenderStart: reportFirstTimeRenderStart,
    wixappsReportFirstTimeRenderFinish: reportFirstTimeRenderFinish,
    onDescriptorFetchSuccess: withActions(({$runInBatch, setDescriptorMetadata}, setDescriptor, requestDescriptor, descriptor) => {
        $runInBatch(() => {
            const value = requestDescriptor.transformFunc(descriptor)
            setDescriptor(requestDescriptor.name, value)
            setDescriptorMetadata(requestDescriptor.name, 'loaded')
        })
    }),
    onItemFetchSuccess: withActions(({$runInBatch}, {requestDescriptor, getWixAppsStore, setWixApps, onLoaded}, response) => {
        const wixappsStore = {wixapps: cloneWixappsStore(getWixAppsStore())}
        const currentValue = _.get(wixappsStore, requestDescriptor.destination)
        const value = requestDescriptor.transformFunc(response, currentValue)
        $runInBatch(() => {
            _.set(wixappsStore, requestDescriptor.destination, value)
            setWixApps(wixappsStore.wixapps)
            onLoaded()
        })
    }),
    onItemFetchFailure: withActions(({$runInBatch}, requestDescriptor, setMetaData) => {
        $runInBatch(() => {
            setMetaData()
            if (requestDescriptor.error) {
                requestDescriptor.error()
            }
        })
    }),
    onVideoThumbnailFetchFailure: withActions(({$runInBatch}, {requestDescriptor, getWixAppsStore, setWixApps, videoNotFoundUrl, setVideoThumbnailError}) => {
        const wixappsStore = {wixapps: cloneWixappsStore(getWixAppsStore())}
        $runInBatch(() => {
            _.set(wixappsStore, [...requestDescriptor.destination, 'imageSrc'], videoNotFoundUrl)
            setWixApps(wixappsStore.wixapps)
            setVideoThumbnailError()
        })
    }),
    removePartsLocation: withActions((
        {$runInBatch, setItemRequestsMetadata},
        getItemsRequestsMetadata,
        getPartLocationsToRemove,
        removePartLocation
    ) => {
        const partLocationsToRemove = getPartLocationsToRemove()
        if (_.isEmpty(partLocationsToRemove)) {
            return
        }

        $runInBatch(() => {
            _.forEach(partLocationsToRemove, ({dataId, packageName}) => {
                removePartLocation(packageName, dataId)

                const itemsRequestsMetadata = getItemsRequestsMetadata()
                _.forEach(itemsRequestsMetadata, (requestMetadata, requestId) => {
                    if (_.includes(requestMetadata.compInfosDataIds, dataId)) {
                        setItemRequestsMetadata(requestId)
                    }
                })
            })
        })
    }),
    videoThumbnailFetch: (isInSSR, fetchFn, url, fetchOptions, type, onSuccess, onFailure) => {
        if (isInSSR) {
            const urlForSSR = _.startsWith(url, 'http') ? url : `https:${url}`
            fetchFn(urlForSSR, fetchOptions, type, onSuccess, onFailure)
        } else {
            $.ajax({
                url,
                dataType: 'jsonp',
                success: onSuccess,
                error: onFailure
            })
        }
    }
}
