import {updateQueryStringParameter, updateHost, DEFAULT_SUB_DOMAIN} from '../../init/functionLibrary/urlUtils'

const LANG_QUERY_KEY = 'lang'

function resolveLanguageUrl(domain, languageCode, resolutionMethod, originalLanguageCode, rawUrl, isPremiumDomain) {
    const baseUrl = stripUrlFromLangParam(rawUrl, isPremiumDomain)
    if (originalLanguageCode === languageCode) {
        return baseUrl
    }
    if (resolutionMethod.toLowerCase() === 'subdomain') {
        const hostWithSubDomain = subDomain => `${subDomain}.${domain}`
        return updateHost(hostWithSubDomain(DEFAULT_SUB_DOMAIN), baseUrl, hostWithSubDomain(languageCode))
    }
    return updateQueryStringParameter(baseUrl, LANG_QUERY_KEY, languageCode)
}

function stripUrlFromLangParam(rawUrl, isPremiumDomain) {
    const {host, full} = rawUrl
    return updateHost(
        host,
        updateQueryStringParameter(full, LANG_QUERY_KEY),
        isPremiumDomain ? undefined : host
    )
}

export {
    LANG_QUERY_KEY,
    resolveLanguageUrl
}