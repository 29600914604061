import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import _ from 'lodash'

export const PUB_SUB_ASPECT_PREFIX = 'pubSub'
const TPA_PUB_SUB_PREFIX = 'TPA_PUB_SUB_'

export const getPubSubAspectKey = key => `${PUB_SUB_ASPECT_PREFIX}_${key}`

const getPubSubAspectActions = aspectActions => _(aspectActions)
    .pickBy((val, key) => _.startsWith(key, PUB_SUB_ASPECT_PREFIX))
    .mapKeys((val, key) => key.replace(/^pubSub_/, ''))
    .value()

const appendPrefix = eventKey => eventKey.indexOf(TPA_PUB_SUB_PREFIX) === 0 ? eventKey : TPA_PUB_SUB_PREFIX.concat(eventKey)

export const pubSubFunctionLibrary = _.mapKeys({
    stripPubSubPrefix: eventKey => eventKey.replace(/^TPA_PUB_SUB_/, ''),
    subscribe: withActions((aspectActions, message, {eventName, appDefinitionId}, comp, isMessageHandled) => {
        if (!isMessageHandled) {
            const pubSubActions = getPubSubAspectActions(aspectActions)
            pubSubActions.setAppEventListener(appDefinitionId, eventName, message.compId, comp)
        }
    }),
    publish: withActions((aspectActions, eventListeners, message, eventName, isPersistent, isMessageHandled) => {
        if (!isMessageHandled) {
            const dataToPublish = {
                eventType: appendPrefix(message.data.eventKey),
                intent: 'addEventListener',
                params: {
                    data: message.data.eventData,
                    name: eventName,
                    origin: message.compId
                }
            }
            _.forEach(eventListeners, comp => comp.sendPostMessage(dataToPublish))
            if (!isPersistent) {
                getPubSubAspectActions(aspectActions).setMessageHandled(message.id, true)
            }
        }
    }),
    persistEvent: withActions((aspectActions, appDefinitionId, eventName, eventData, isMessageHandled, compId, messageId, intent) => {
        if (!isMessageHandled) {
            const pubSubActions = getPubSubAspectActions(aspectActions)
            const dataToPersist = {
                data: eventData,
                name: eventName,
                origin: compId
            }
            pubSubActions.pushEventData(appDefinitionId, eventName, dataToPersist)
            pubSubActions.setActionIntent(appDefinitionId, eventName, intent)
            pubSubActions.setMessageHandled(messageId, true)
        }
    }),
    unsubscribe: withActions((aspectActions, appDefinitionId, eventName, compId, isMessageHandled, messageId) => {
        if (!isMessageHandled) {
            const pubSubActions = getPubSubAspectActions(aspectActions)
            pubSubActions.setAppEventListener(appDefinitionId, eventName, compId, undefined)
            pubSubActions.setMessageHandled(messageId, true)
        }
    }),
    deleteCompListeners: withActions((aspectActions, pubSubHub, appDefinitionId, compId) => {
        const appData = pubSubHub[appDefinitionId]
        const pubSubActions = getPubSubAspectActions(aspectActions)
        _.forOwn(appData, (v, eventName) => {
            pubSubActions.setAppEventListener(appDefinitionId, eventName, compId, undefined)
        })
    }),
    deleteWixCodeEvents: withActions((aspectActions, pubSubHub) => {
        _.forEach(pubSubHub, (appData, appDefId) => {
            _.forEach(appData, (data, key) => {
                if (data.intent === 'WIX_CODE_SITE_API') {
                    delete appData[key]
                }
            })
            const pubSubActions = getPubSubAspectActions(aspectActions)
            pubSubActions.setPubSub(appDefId, appData)
        })
    }),
    markMessageHandled: withActions((aspectActions, messageId) => {
        getPubSubAspectActions(aspectActions).setMessageHandled(messageId, true)
    })
}, (value, key) => getPubSubAspectKey(key))
