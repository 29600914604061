import _ from 'lodash'

const fillMissingIdsForItem = (itemDef, indexAtView) => {
    itemDef.id = itemDef.id || itemDef.data || `def_${indexAtView}`
    let ret = indexAtView + 1

    // look for other item definitions recursively (in inner layout objects)
    if (itemDef.comp && itemDef.comp.items) {
        _.forEach(itemDef.comp.items, item => {
            ret = fillMissingIdsForItem(item, ret)
        })
    }
    // .. and in template cases
    if (itemDef.comp && itemDef.comp.templates) {
        _.forEach(itemDef.comp.templates, value => {
            ret = fillMissingIdsForItem(value, ret)
        })
    }
    // .. and in switch cases
    if (itemDef.comp && itemDef.comp.cases) {
        _.forEach(itemDef.comp.cases, value => {
            if (_.isArray(value)) {
                _.forEach(value, item => {
                    ret = fillMissingIdsForItem(item, ret)
                })
            } else {
                ret = fillMissingIdsForItem(value, ret)
            }
        })
    }
    // ... and deal with the table layout which was coded while on acid...
    if (itemDef.comp && itemDef.comp.columns && itemDef.comp.name === 'Table') {
        _.forEach(itemDef.comp.columns, columnItem => {
            _.forEach(['item', 'header', 'footer'], propName => {
                if (columnItem[propName] !== undefined) {
                    ret = fillMissingIdsForItem(columnItem[propName], ret)
                }
            })
        })
    }

    return ret
}

/**
 * Stops traversal if the callback returns false.
 */
function traverseViews(viewDef, callback) {
    let returnValue = callback(viewDef)
    if (returnValue !== false) {
        const childCompProps = ['items', 'cases', 'templates']
        _.forEach(childCompProps, prop => { //eslint-disable-line consistent-return
            if (viewDef && viewDef.comp && viewDef.comp[prop]) {
                _.forEach(viewDef.comp[prop], child => {
                    if (_.isArray(child)) {
                        _.forEach(child, arrItem => {
                            returnValue = traverseViews(arrItem, callback)
                            return returnValue
                        })
                    } else {
                        returnValue = traverseViews(child, callback)
                    }
                    return returnValue
                })
                return returnValue
            }
        })
    }
    return returnValue
}

const fillViewDefMissingIDs = viewDef => fillMissingIdsForItem(viewDef, 0)

export {
    fillViewDefMissingIDs,
    traverseViews
}
