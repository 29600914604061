'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const shouldComponentUpdateHOC = require('./ShouldComponentUpdateHOC')

class StylesContainer extends React.Component {
    static propTypes = {
        children: PropTypes.node
    }

    render() {
        return <div data-aid="stylesContainer">{this.props.children}</div>
    }
}

module.exports = shouldComponentUpdateHOC(StylesContainer)
