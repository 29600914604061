const thirdPartyAnalytics = require('thirdPartyAnalytics') //eslint-disable-line import/no-unresolved
const _ = require('lodash')

function getDefaultChannels(props, promoteAnalyticsAdapter) {
    const options = {
        reportType: 'event',
        adapter: 'pa',
        sampleRatio: 0
    }
    return [{
        // Sends events to frog.wix.com
        name: promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
        report: thirdPartyAnalytics.decorateReporter(
            props,
            promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
            params => props.reportBI(options, params)
        )
    }]
}

function getEmbeddedChannels(props) {
    if (typeof window === 'undefined' || !window.promoteAnalyticsChannels) {
        return []
    }

    const embeddedChannels = window.promoteAnalyticsChannels.map(channel => ({
        name: channel.name,
        events: channel.events,
        report: thirdPartyAnalytics.decorateReporter(props, channel.name, channel.report),
        config: channel.config
    }))
    window.promoteAnalyticsChannels = undefined
    return embeddedChannels
}

function getManagedChannels(props, promoteAnalyticsAdapter) {
    const accountNameToChannelData = {
        facebookRemarketing: {
            name: promoteAnalyticsAdapter.channelNames.FACEBOOK_PIXEL,
            report: (eventType, eventName, data) => _.isFunction(window.fbq) && window.fbq(eventType, eventName, data || {})
        },
        googleAnalytics: {
            name: promoteAnalyticsAdapter.channelNames.GOOGLE_ANALYTICS,
            report() {
                return _.isFunction(window.ga) && window.ga(...arguments) //eslint-disable-line fp/no-arguments
            }
        },
        googleTagManager: {
            name: promoteAnalyticsAdapter.channelNames.GOOGLE_TAG_MANAGER,
            report: eventParams => _.isArray(window.dataLayer) && window.dataLayer.push(...eventParams)
        },
        yandexMetrika: {
            name: promoteAnalyticsAdapter.channelNames.YANDEX_METRICA,
            report: () => window.ym && _.isFunction(window.ym.hit) && window.ym.hit(props.currentUrl.full)
        }
    }

    const channels = _.filter(accountNameToChannelData, channelData => hasScriptsLoaded(props, channelData.name))
    return channels
}

function hasScriptsLoaded(props, channelName) {
    const loadedScripts = Object.values(props.loadedScripts).map(({name}) => name)
    return loadedScripts.some(scriptName => channelName === scriptName)
}

const initGoogleAnalytics = setIsGoogleAnalyticsInitialized => {
    const isGoogleAnalyticsObjectReady = typeof window.ga === 'function'
    if (isGoogleAnalyticsObjectReady) {
        window.ga('require', 'ec')
        setIsGoogleAnalyticsInitialized(true)
    }
}

module.exports = (props, eventName, params, options, promoteAnalyticsAdapter, {setIsAdapterInitialized, setIsGoogleAnalyticsInitialized, setPendingEvents}) => {
    if (!promoteAnalyticsAdapter.api) {
        return
    }
    const isTagManagerReady = props.isTagManagerReady
    if (!props.isAdapterInitialized) {
        setPendingEvents(props.pendingEvents.concat({eventName, params, options}))
        const defaultChannels = getDefaultChannels(props, promoteAnalyticsAdapter)
        promoteAnalyticsAdapter.api.init(defaultChannels)
        promoteAnalyticsAdapter.api.addListener([promoteAnalyticsAdapter.listeners[promoteAnalyticsAdapter.channelNames.WIX_DEVELOPERS_ANALYTICS]], props)
        if (isTagManagerReady) {
            const channels = [...getManagedChannels(props, promoteAnalyticsAdapter), ...getEmbeddedChannels(props)]
            promoteAnalyticsAdapter.api.init(channels)
            if (!props.isGoogleAnalyticsInitialized) {
                initGoogleAnalytics(setIsGoogleAnalyticsInitialized)
            }
            props.pendingEvents.forEach(event => {
                promoteAnalyticsAdapter.api.trackEvent(event.eventName, event.params, {...event.options, reportToManagedChannels: true})
            })
            setPendingEvents([])
            setIsAdapterInitialized(true)
        }
        return
    }
    promoteAnalyticsAdapter.api.trackEvent(eventName, params, {...options, reportToManagedChannels: true})
}
