export const CATEGORIES = {
    ANALYTICS: 'analytics',
    ADVERTISING: 'advertising'
}

export const EVENT_NAMES = {
    TAG_MANAGER_LOADED: 'TagManagerLoaded',
    TAGS_LOADING: 'LoadingTags',
    TAG_LOADED: 'TagLoaded',
    TAG_LOAD_ERROR: 'TagLoadError'
  }

export const LOAD_STATUS = {
    ERROR: 'error',
    SUCCESS: 'success'
}

export const SUPPORTED_CHANNELS = {
    FACEBOOK_PIXEL: 'facebookPixel',
    GOOGLE_ANALYTICS: 'googleAnalytics',
    GOOGLE_TAG_MANAGER: 'googleTagManager',
    WIX_ANALYTICS: 'wixAnalytics',
    BI_ANALYTICS: 'biAnalytics',
    GTAG: 'gtag',
    VK_RETARGETING: 'vkRetargeting',
    YANDEX_METRICA: 'yandexMetrica',
    WIX_DEVELOPERS_ANALYTICS: 'wix-developers-analytics'
}
