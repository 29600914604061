import _ from 'lodash'
import {urlUtils} from 'santa-core-utils'
import {getSiteDataDestination, getBlogCategories, getBlogCategoryByName, getBlogCategoriesFromPackageData} from '../wixappsDataHandler'
import {resolveCategories} from './mediaPostConverter'
import transformAndSetMetaData from '../transformAndSetMetaData'

const queryBlogCategories = ({getExternalBaseUrl}, compData, appService) => {
    if (appService.packageName !== 'blog') {
        return []
    }

    const url = `${urlUtils.baseUrl(getExternalBaseUrl())}/apps/lists/1/Query?consistentRead=false`

    const data = {
        collectionId: 'Settings',
        filter: {_iid: 'categories'},
        storeId: appService.datastoreId
    }

    const transformFunc = (responseData, currentValue) => {
        const categories = _.get(responseData.payload, 'items[0].categories')
        if (categories) {
            const orderedCategories = []
            const categoryById = {}
            _.forEach(categories, category => {
                orderedCategories.push(category)
                categoryById[category.id] = category
                _.forEach(category.subcategories, subcategory => {
                    orderedCategories.push(subcategory)
                    categoryById[subcategory.id] = subcategory
                })
            })

            currentValue.categories = {
                countedCategories: 0,
                postCountById: {},
                categories,
                orderedCategories,
                categoryById
            }
        } else {
            currentValue.categories = {}
        }
        resolveCategories(currentValue)
        return currentValue
    }

    return [{
        destination: getSiteDataDestination(appService.packageName),
        name: appService.packageName,
        url,
        data,
        transformFunc
    }]
}

function getCategoryIds(category) {
    const subcategoryIds = _.map(category.subcategories, 'id')
    return [category.id].concat(subcategoryIds)
}

const extendParamsWithBlogCategoryFilter = (appPartDrcAPI, params) => {
    const categoryNames = params.categoryNames && JSON.parse(params.categoryNames)
    if (categoryNames) {
        const areCategoriesReady = getBlogCategories(appPartDrcAPI.wixapps)
        if (!areCategoriesReady) {
            return false
        }

        let categoryIds = []
        _.forEach(categoryNames, name => {
            const category = getBlogCategoryByName(appPartDrcAPI.wixapps, name)
            if (category) {
                categoryIds = categoryIds.concat(getCategoryIds(category))
            }
        })

        if (!params.filter) {
            params.filter = {}
        }
        params.filter.categoryIds = {$in: categoryIds}
    }

    return true
}

function queryBlogCategoryPostCounts(appPartDrcAPI, compInfo) {
    const compData = compInfo.data
    const appInnerId = compData.appInnerID
    const appService = appPartDrcAPI.getClientSpecMapEntry(appInnerId)
    const categoryStore = getBlogCategories(appPartDrcAPI.wixapps)

    const packageName = appService.packageName // Actually it's always "blog".

    const compId = compData.id

    if (categoryStore.countedCategories === categoryStore.orderedCategories.length) { // Is post counting complete?
        return []
    }

    const MAX_OPERATIONS_PER_BATCH = 24
    const categoriesToBeResolved = categoryStore.orderedCategories.slice(categoryStore.countedCategories, categoryStore.countedCategories + MAX_OPERATIONS_PER_BATCH)
    // Batch request may be quite heavy so that a limit for number of operations is used to minimize chance of
    // catching timeout.

    // Query post count for each category.
    const batchOperations = _.map(categoriesToBeResolved, category => ({
        name: 'Query',
        params: {
            collectionId: 'Posts',
            fields: [''], // Avoid returning post data - the response will contain only meta fields (like _iid).
            filter: {
                categoryIds: {$in: getCategoryIds(category)},
                'date.iso': {$lte: '$now'}, // Don't count scheduled posts.
                deleted: {$ne: true}, // Don't count deleted posts.
                draft: {$ne: true} // Don't count draft posts.
            },
            getTotalCount: true,
            limit: 1, // A smaller number has no effect.
            storeId: appService.datastoreId
        }
    }))

    function transformResponse(responseData, currentValue) {
        // The category store from above can be obsolete if page JSON revision changes.
        const currentCategoryStore = getBlogCategoriesFromPackageData(currentValue)
        const resolvedCategories = responseData.payload.results
        currentValue.categories.countedCategories += resolvedCategories.length
        _.forEach(resolvedCategories, (result, index) => {
            const category = categoriesToBeResolved[index]
            currentCategoryStore.postCountById[category.id] = result.payload.totalCount
        })

        if (currentValue.categories.countedCategories === currentValue.categories.orderedCategories.length) { // Is post counting complete?
            const DATA_PATH_FRAGMENT = 'categoriesWithPostCounts'
            const packageData = currentValue

            // Ensure that categories with post counts are created.
            const DATA_PATH = ['items', DATA_PATH_FRAGMENT]
            if (!_.get(packageData, DATA_PATH)) {
                const categoriesWithPostCounts = _.cloneDeep(currentCategoryStore.categories) || []

                const resolvePostCount = category => {
                    category.postCount = currentCategoryStore.postCountById[category.id] // eslint-disable-line santa/no-side-effects
                }

                _.forEach(categoriesWithPostCounts, category => {
                    resolvePostCount(category)
                    _.forEach(category.subcategories, resolvePostCount)
                })

                _.set(packageData, DATA_PATH, categoriesWithPostCounts)
            }

            packageData[compId] = [DATA_PATH_FRAGMENT]
        }

        // Use the categories with post counts as data for the component.

        return currentValue
    }

    return [{
        destination: getSiteDataDestination(packageName),
        name: packageName,
        url: `${urlUtils.baseUrl(appPartDrcAPI.getExternalBaseUrl())}/apps/lists/1/Batch?consistentRead=false`,
        data: {operations: batchOperations},
        transformFunc: transformAndSetMetaData.bind(this, transformResponse, appPartDrcAPI, packageName, compId)
    }]
}

export {
    queryBlogCategories,
    getCategoryIds,
    queryBlogCategoryPostCounts,
    extendParamsWithBlogCategoryFilter
}
