import {objectUtils} from 'santa-core-utils'
import _ from 'lodash'

const ERROR_TEMPLATE_MISSING_ITEM_VIEW = 'The template is missing an item view'

function replaceNestedFieldRefs(fieldMap, viewDefPart) { //eslint-disable-line consistent-return
    if (!_.isObject(viewDefPart)) {
        return viewDefPart
    }

    _.forEach(viewDefPart, innerViewDefPart => {
        replaceNestedFieldRefs(fieldMap, innerViewDefPart)
    })

    if (_.has(viewDefPart, 'data') && fieldMap[viewDefPart.data]) {
        viewDefPart.data = fieldMap[viewDefPart.data]
    }
}

function isSameFieldType(firstField, secondField) {
    const firstShowAsHint = firstField.metadata && firstField.metadata.showAsHint
    const secondShowAsHint = secondField.metadata && secondField.metadata.showAsHint
    return firstField.type === secondField.type && firstShowAsHint === secondShowAsHint
}

function matchFieldsByType(sourceFields, targetFields) {
    const ignoreFields = ['title', 'links']
    const fieldMap = _.zipObject(ignoreFields, ignoreFields)

    sourceFields = _.reject(sourceFields, field => _.includes(ignoreFields, field.name))
    targetFields = _.reject(targetFields, field => _.includes(ignoreFields, field.name))

    _.forEach(sourceFields, sourceField => {
        const matchingTargetFieldIndex = _.findIndex(targetFields, isSameFieldType.bind(null, sourceField))
        if (matchingTargetFieldIndex !== -1) {
            fieldMap[sourceField.name] = targetFields[matchingTargetFieldIndex].name
            targetFields.splice(matchingTargetFieldIndex, 1)
        } else {
            fieldMap[sourceField.name] = null
        }
    })

    return fieldMap
}

function getMatchingViewsForType(views, templateTypeDef, existingTypeDef) {
    if (views.length === 0) {
        throw new Error(ERROR_TEMPLATE_MISSING_ITEM_VIEW)
    }

    const fieldMap = matchFieldsByType(templateTypeDef.fields, existingTypeDef.fields)

    return _.map(views, view => {
        const clonedView = _.cloneDeep(view)
        replaceNestedFieldRefs(fieldMap, clonedView)
        return clonedView
    })
}

function getViewTemplate(template, possibleView) {
    const viewsForType = _.filter(template.views, view => possibleView.forType === 'Array' ? view.forType === 'Array' : view.forType !== 'Array')

    return _.find(viewsForType, {format: possibleView.format}) || _.head(viewsForType)
}

function generateView(template, viewType, partType) {
    const viewTemplate = getViewTemplate(template, viewType)

    let newView
    if (viewType.forType === 'Array') {
        newView = _.cloneDeep(viewTemplate)
        const itemViewRefs = objectUtils.filter(newView, o => o.id === 'listItem')
        _.forEach(itemViewRefs, v => {
            v.comp.name = viewType.name
        })
    } else {
        newView = getMatchingViewsForType([viewTemplate], template.type, partType)[0]
    }

    return _.assign(newView, viewType)
}

export {
    generateView
}
