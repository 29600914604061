import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'

export const name = 'PopoverAspect'

export const CLOSE_POPOVER_TIMEOUT = 500

const CLOSE_METHODS = {
    MOUSE_OUT: 'mouseOut',
    OUTER_CLICK: 'outerClick',
    MANUAL: 'manual'
}

const DEFAULT_OPTIONS = {
    closeMethod: CLOSE_METHODS.OUTER_CLICK
}

export const defaultModel = {
    popoverLayerId: null,
    popoverCloseTimeout: {},
    popoverData: {}
}

const getTargetPortal = (popoverLayerId, windowObject) => popoverLayerId ? windowObject.document.getElementById(popoverLayerId) : null

const getTargetBounds = (windowObject, scrollPosition, popoverLayerId, getIsComponentPinned, targetId) => {
    const popoverLayerElement = windowObject.document.getElementById(popoverLayerId)
    const targetElement = windowObject.document.getElementById(targetId)
    const portalBoundingRect = popoverLayerElement.getBoundingClientRect()
    const targetBoundingRect = targetElement.getBoundingClientRect()
    const isComponentPinned = getIsComponentPinned(targetId)
    return {
        top: targetBoundingRect.y + (isComponentPinned ? 0 : scrollPosition.y),
        left: targetBoundingRect.x - portalBoundingRect.x,
        width: targetBoundingRect.width,
        height: targetBoundingRect.height,
        ...isComponentPinned && {position: 'fixed'}
    }
}

const openPopover = withActions(({setPopoverData}, popoverId, modeId, activateModeById, getTargetBoundsRef, registerToDocumentClickEvent, targetId, options) => {
    const targetBounds = getTargetBoundsRef(targetId)
    const optionsWithDefaults = _.defaults(options, DEFAULT_OPTIONS)
    if (optionsWithDefaults.closeMethod !== CLOSE_METHODS.MANUAL) {
        registerToDocumentClickEvent(popoverId)
    }
    setPopoverData(popoverId, {targetBounds, modeId, options: optionsWithDefaults})
    activateModeById(null, null, modeId)
})

const closePopoverHandler = (setPopoverData, popoverId, popoverData, deactivateModeById, unRegisterToDocumentClickEvent) => {
    if (_.get(popoverData, 'options.closeMethod') !== CLOSE_METHODS.MANUAL) {
        unRegisterToDocumentClickEvent(popoverId)
    }
    setPopoverData(popoverId)
    deactivateModeById(null, null, popoverData.modeId)
}

const closePopover = withActions(({setPopoverData}, popoverId, popoverData, deactivateModeById, unRegisterToDocumentClickEvent) => {
    closePopoverHandler(setPopoverData, popoverId, popoverData, deactivateModeById, unRegisterToDocumentClickEvent)
})

const closePopoverWithTimeoutIfNeeded = withActions(({setPopoverCloseTimeout, setPopoverData}, popoverId, popoverData, deactivateModeById, unRegisterToDocumentClickEvent) => {
    if (_.get(popoverData, 'options.closeMethod') === CLOSE_METHODS.MOUSE_OUT) {
        const closeTimeoutId = setTimeout(() => {
            setPopoverCloseTimeout(popoverId)
            closePopoverHandler(setPopoverData, popoverId, popoverData, deactivateModeById, unRegisterToDocumentClickEvent)
        }, CLOSE_POPOVER_TIMEOUT)
        setPopoverCloseTimeout(popoverId, closeTimeoutId)
    }
})

const cancelClosePopoverTimeoutIfNeeded = withActions(({setPopoverCloseTimeout}, popoverId, timeoutId) => {
    if (timeoutId) {
        clearTimeout(timeoutId)
        setPopoverCloseTimeout(popoverId)
    }
})

export const functionLibrary = {
    openPopover,
    closePopover,
    closePopoverWithTimeoutIfNeeded,
    cancelClosePopoverTimeoutIfNeeded,
    getTargetPortal,
    getTargetBounds
}
