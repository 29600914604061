import _ from 'lodash'
import {
    getMediaPostCollection,
    fixMediaPostDataRefs,
    translateDefaultPosts,
    getPostWithoutCertainExcerptStyling,
    addAuthorFieldWhenMissing,
    getPostWithConvertedMobileTitle,
    fixMasterPageIdInLinksInside,
    resolveCategories,
    convertMediaPost,
    getYouTubePreviewUrl
} from './blog/mediaPostConverter'

const setVideoItemImgSrc = (item, imgSrc) => {
    _.set(item, 'coverImageData.video.imageSrc', imgSrc)
    _.set(item, 'video.imageSrc', imgSrc)
}

export default function transformMediaItemsResponse(compId, collectionId, responseData, currentValue, lang, videoNotFoundUrl) {
    currentValue[`${compId}_extraData`] = _.omit(responseData.payload, ['items', 'referencedItems', 'unreferencedItems'])
    currentValue[compId] = _.map(responseData.payload.items, item => getMediaPostCollection(item, collectionId))

    currentValue.items = currentValue.items || {}
    currentValue.items[collectionId] = currentValue.items[collectionId] || {}
    currentValue.items.converted = currentValue.items.converted || {}
    currentValue.items.converted[collectionId] = currentValue.items.converted[collectionId] || {}

    _.forEach(responseData.payload.items, item => {
        let fixedItem = fixMediaPostDataRefs(item)
        fixedItem = translateDefaultPosts(fixedItem, lang)

        fixedItem = getPostWithoutCertainExcerptStyling(fixedItem)

        const currentItem = currentValue.items[collectionId][item._iid] || {}
        fixedItem = _.assign(currentItem, fixedItem)

        fixedItem = addAuthorFieldWhenMissing(fixedItem)
        fixedItem = getPostWithConvertedMobileTitle(fixedItem)

        fixMasterPageIdInLinksInside(fixedItem)

        currentValue.items[collectionId][item._iid] = fixedItem
        resolveCategories(currentValue, fixedItem)


        if (fixedItem._type === 'MediaPost') {
            const convertedItem = convertMediaPost(fixedItem)
            currentValue.items.converted[collectionId][convertedItem._iid] = convertedItem
            resolveCategories(currentValue, convertedItem)
        }

        const videoData = _.get(fixedItem, 'coverImageData.video', null)
        if (videoData) {
            const videoType = videoData.videoType
            if (videoType === 'YOUTUBE') {
                const youTubePreviewUrl = videoData.videoId ? getYouTubePreviewUrl(videoData) : videoNotFoundUrl
                setVideoItemImgSrc(fixedItem, youTubePreviewUrl)
            } else if (!videoType) {
                setVideoItemImgSrc(fixedItem, videoNotFoundUrl)
            }
        }
    })

    _.forEach(responseData.payload.referencedItems, (refItem, refItemKey) => {
        const colId = refItemKey.split('/')[0]
        const iid = refItemKey.split('/')[1]
        currentValue.items[colId] = currentValue.items[colId] || {}
        currentValue.items[colId][iid] = refItem
    })

    return currentValue
}
