import {layoutUtils} from 'santa-core-utils'
import {matrixCalculations} from 'coreUtils' //eslint-disable-line import/no-unresolved
import galleriesCommon from 'galleriesCommon' //eslint-disable-line import/no-unresolved
import _ from 'lodash'

const galleriesHelperFunctions = galleriesCommon.utils.galleriesHelperFunctions

export const name = 'ComponentStyleAspect'

export const functionLibrary = {
    getBaseStyle: (layout, screenLayout, layoutMechanism, shouldAddRotatedInlineStyle) => {
        const getScreenLayoutFunc = () => screenLayout
        const baseStyle = layoutUtils.getStyle(layout, getScreenLayoutFunc, layoutMechanism)
        return shouldAddRotatedInlineStyle ? baseStyle : _.omit(baseStyle, ['transform'])
    },
    getMatrixGalleryHeight: (compStructure, compProp, galleryHeight, itemsNumBeforeChange, itemsNumAfterChange) => {
        const numberOfRows = matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, itemsNumBeforeChange)
        const newNumberOfRows = matrixCalculations.getAvailableRowsNumber(compProp.maxRows, compProp.numCols, itemsNumAfterChange)
        const calculatedGalleryHeight = galleriesHelperFunctions.getGalleryHeight(numberOfRows, newNumberOfRows, compProp.margin, compStructure.skin, galleryHeight)
        const newGalleryHeight = Math.max(galleriesHelperFunctions.MIN_GALLERY_HEIGHT, calculatedGalleryHeight)
        return newGalleryHeight
    }
}
