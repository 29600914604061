import _ from 'lodash'
import {imageUtils} from 'santa-core-utils'
import {withActions} from 'carmi-host-extensions'
export const name = 'VerticalAnchorsMenuAspect'

export const defaultModel = {
    autoColorObservers: [],
    imagesToCheckColorOf: {},
    activeAnchorObservers: [],
    colorInfo: {
    }
}

const getColorInfoPath = (rootId, compId) => `${rootId}||${compId}`


const notifyObservers = observers => {
    _.defer(() => { // defer in order to make sure all fetchers have been calculated before notifying the observers (BOLT-610)
        _.forEach(observers, observer => {
            observer()
        })
    })
}

const checkMeanColorOfImage = (imageData, updateInformation) => {
    const onSuccess = (brightness, alpha) => {
        updateInformation(imageData.compId, imageData.rootId, {brightness, alpha})
    }

    // todo TBD
    const onError = () => null
    imageUtils.getImageMeanBrightness(imageData.thumbnailImageUrl, {width: 1, height: 1}, onSuccess, onError)
}

const checkMeanColorOfImages = ({setImagesToCheckColorOf, updateInformation}, imagesToCheckColorOf) => {
    _.forOwn(imagesToCheckColorOf, imageData => checkMeanColorOfImage(imageData, updateInformation))

    setImagesToCheckColorOf({}) // check why it's array in original code
}

const registerToMeanColor = withActions((
    {setAutoColorObservers, setImagesToCheckColorOf},
    {autoColorObserversLength, imagesToCheckColorOf, updateInformation},
    observer
) => {
    setAutoColorObservers(autoColorObserversLength, 0, observer)
    checkMeanColorOfImages({setImagesToCheckColorOf, updateInformation}, imagesToCheckColorOf)
})

const unregisterToMeanColor = withActions(({setAutoColorObservers}, autoColorObservers, observer) => {
    const index = autoColorObservers.findIndex(observerItem => observerItem === observer)
    setAutoColorObservers(index, 1)
})

const updateImageInfo = withActions((
    {setImagesToCheckColorOfItem},
    {autoColorObserversLength, updateInformation},
    compId,
    rootId,
    thumbnailImageUrl
) => {
    const imageData = {
        thumbnailImageUrl,
        compId,
        rootId
    }

    if (autoColorObserversLength) {
        checkMeanColorOfImage(imageData, updateInformation)
    } else {
        const mapKey = `${rootId.toString()}-${compId.toString()}`
        setImagesToCheckColorOfItem(mapKey, imageData)
    }
})

const updateInformation = withActions(({setColorInfo}, autoColorObservers, compId, rootId, colorInfo) => {
    const colorInfoPath = getColorInfoPath(rootId, compId)
    setColorInfo(colorInfoPath, colorInfo)
    notifyObservers(autoColorObservers)
})

const registerToActiveAnchor = withActions(({setActiveAnchorObservers}, {activeAnchorObserversLength}, observer) => {
    setActiveAnchorObservers(activeAnchorObserversLength, 0, observer)
})

const unregisterToActiveAnchor = withActions(({setActiveAnchorObservers}, {activeAnchorObservers}, observer) => {
    const index = activeAnchorObservers.findIndex(observer)
    setActiveAnchorObservers(index, 1)
})

const thunkize = value => () => value

export const functionLibrary = {
    registerToMeanColor,
    unregisterToMeanColor,
    updateInformation,
    registerToActiveAnchor,
    unregisterToActiveAnchor,
    thunkize,
    updateImageInfo,
    sortOverlappingColorInfo: arr => _.sortBy(arr, colorInfo => colorInfo.layout.width * colorInfo.layout.height),
    notifyObservers
}

export function init(actions, {eventsManager, initialData}) {
    eventsManager.on('windowScroll', initialData.notifyAutoColorObservers)
}
