import _ from 'lodash'
import {withActions} from '../withActions'
import {containerBackgroundUtils, svgMask} from 'santa-core-utils'
import {colorParser} from '../utils/colors'

export const name = 'MediaAspect'
export const constants = {
    REGISTERED_PLAYERS: 'registeredPlayers',
    PLAYBACK_STATE: 'playbackState',
    QUALITY_STATE: 'qualityState',
    DESKTOP_CONTEXT_LIMIT: 14,
    MOBILE_CONTEXT_LIMIT: 6
}

export const defaultModel = {
    [constants.REGISTERED_PLAYERS]: {},
    [constants.PLAYBACK_STATE]: {},
    [constants.QUALITY_STATE]: {}
}

const WEBGL_CONTEXT_COUNTER = {
    count: 0
}

export const functionLibrary = {

    updatePlayerState: withActions((aspectActions, currentState, id, newState) =>
        aspectActions.updatePlayerState(id, {...currentState, ...newState})),

    updateQualityState: withActions((aspectActions, currentState, id, newState) =>
        aspectActions.updateQualityState(id, {...currentState, ...newState})),

    initFeatureDetections: withActions((aspectActions, shouldInit, windowObject) => {
        if (shouldInit) {
            /* video playsInline attribute */
            const playsInline = /(iPhone|iPod)/g.test(windowObject.navigator.userAgent) ? 'playsInline' in windowObject.document.createElement('video') : true

            /* CSS mask-image */
            const video = windowObject.document.createElement('video')
            const style = video && video.style
            const hasCSSMask = 'maskRepeat' in style || 'webkitMaskRepeat' in style

            aspectActions.updateQualityState('featureDetections', {playsInline, hasCSSMask})
        }
    }),
    getImageUrlParams(...params) {
        return containerBackgroundUtils.getImageUrlPreMeasureParams(...params)
    },

    registerPlayer: withActions((aspectActions, id, viewportAPI, isInSSR, getCurrentState, externalId, options) => {
        if (isInSSR) {
            return
        }
        options = options || {}
        const mediaData = _.get(options, 'mediaData')
        const videoId = _.get(mediaData, 'videoId')
        const services = options.services || {}

        aspectActions.updatePlayerState(id, {...getCurrentState(), ...{
            playerType: options.playerType || 'none',
            videoId,
            duration: _.get(mediaData, 'duration', 0)
        }})
        aspectActions.updateRegisteredPlayers(id, services)

        if (services.viewport) {
            viewportAPI.register(name, id, services.viewport.callback)
        }
    }),

    unregisterPlayer: withActions((aspectActions, id, viewportAPI) => {
        aspectActions.updateRegisteredPlayers(id)
        aspectActions.updatePlayerState(id, undefined)
        viewportAPI.unregister(name, id)
    }),

    addWebGLContext: limit => {
        const count = WEBGL_CONTEXT_COUNTER.count

        if (count < limit) {
            WEBGL_CONTEXT_COUNTER.count += 1

            return true
        }

        return false
    },

    removeWebGLContext: () => {
        WEBGL_CONTEXT_COUNTER.count = Math.max(0, WEBGL_CONTEXT_COUNTER.count - 1)
    },

    triggerSiteVisibilityChange: (players, state) =>
        Object.keys(players).forEach(id => {
            const services = players[id]

            if (services.visibility && services.visibility.callback) {
                services.visibility.callback(state)
            }
        }),

    getCSSMask: (svgString, maskData) => {
        if (!maskData || !svgString) {
            return null
        }

        const {x, y, width, height} = maskData

        return svgMask.getCSSMask(svgString, {
            x: `${x}%`,
            y: `${y}%`,
            width: `${width}%`,
            height: `${height}%`
        })
    },

    getDropShadowFilter: (boxShadow, colorsMap) => {
        if (!boxShadow) {
            return null
        }


        const {offsetX, offsetY, blurRadius, color} = boxShadow
        const rgba = colorParser.getColor(color, colorsMap)

        const cssValue = `drop-shadow(${offsetX.value}${offsetX.unit} ${offsetY.value}${offsetY.unit} ${blurRadius.value}${blurRadius.unit} ${rgba})`

        return {
            filter: cssValue,
            WebkitFilter: cssValue
        }
    }
}

export function init({}, {eventsManager, initialData: {triggerSiteVisibilityChange}}) {
    eventsManager.on('visibilityChange', triggerSiteVisibilityChange)
}
