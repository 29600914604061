import * as constants from '../../santa-renderer/src/utils/constants'
import {LayoutData} from './layoutData'

const {
    TEMPLATE_COMP_ID,
    PARENT,
    META_DATA,
    DATA_QUERY,
    PROPERTY_QUERY,
    DESIGN_QUERY,
    BEHAVIOR_QUERY,
    CONNECTION_QUERY,
    LAYOUT_QUERY,
    BREAKPOINTS_QUERY,
    LAYOUT,
    SKIN,
    STYLE_ID,
    ID,
    TYPE,
    COMPONENT_TYPE,
    TRANSITION_QUERY,
    TRANSFORMATION_QUERY
} = constants.pointers.components.PROPERTY_TYPES
const {
    DATA,
    DESIGN,
    PROPERTIES,
    STYLE,
    BEHAVIORS,
    CONNECTIONS,
    BREAKPOINTS,
    VARIANTS,
    LAYOUT: LAYOUT_DATA,
    TRANSITIONS,
    TRANSFORMATIONS
} = constants.pointers.data.DATA_MAPS

export interface SessionInfo {
    hs: number,
    svSession: string,
    ctToken: string,
    isAnonymous: boolean,
    visitorId: string
}

export interface Docking {
    left,
    right,
    top,
    bottom: {
        margin?: Partial<{
            vw: number
            px: number
        }>
    }
}

export interface Layout {
    x,
    y,
    width,
    height: number
    rotationInDegrees: number
    fixedPosition: boolean
    scale: number
    docked: Partial<Docking>
}

export interface ComponentMetaData {
    isPreset: boolean,
    pageId: string
}

export interface BaseDataItem {
    id: string
    type: string
}

export interface Style {
    type: string
    skin: string
    style: {
        properties: {
            [id: string]: string
        }
    }
}

export interface RefArray {
    type: 'RefArray'
    values: Array<string | {[x: string]: any}>
}

interface BreakpointRelation extends BaseDataItem { // TODO remove type after breakpoints are fixed to use variants models
    type: 'BreakpointRelation',
    ref: string | {[x: string]: any}, 
    breakpoint: string,
}
 interface VariantRelation extends BaseDataItem {
    type: 'VariantRelation',
    to: string,
    variants: Array<string>
}

export type LayoutInBreakpoint = BreakpointRelation | VariantRelation

export interface BehaviorItem {}

export enum ConnectionType {
    WixCodeConnectionItem = 'WixCodeConnectionItem',
    ConnectionItem = 'ConnectionItem'
}

interface ControllerConnectionItem {
    type: ConnectionType.ConnectionItem,
    role: string,
    controllerId: string,
    config?: any,
    isPrimary: boolean
}

interface WixCodeConnectionItem {
    type: ConnectionType.WixCodeConnectionItem,
    role: string
}

export type ConnectionItem = ControllerConnectionItem | WixCodeConnectionItem

export interface ConnectionList {
    id: string,
    type: string,
    items: ConnectionItem[]
}

export interface BreakpointRange {
    type: 'BreakpointRange'
    min?: number
    max?: number
}

interface BreakpointsData {
    type: 'BreakpointsData',
    values: string[]
}

type BreakpointItem = BreakpointRange | BreakpointsData

export interface BreakpointData {
    [breakpointQuery: string]: BreakpointItem
}

export interface DataMap {
    [STYLE]: {
        THEME_DATA: {
            font: string
            color: string
        }
    } & { [styleId: string]: Style | RefArray | LayoutInBreakpoint }
    [DESIGN]: {}
    [DATA]: {}
    [PROPERTIES]: {}
    [CONNECTIONS]: { [connectionId: string]: ConnectionList }
    mobile_hints: {}
    [LAYOUT_DATA]: LayoutData
    [BREAKPOINTS]: BreakpointData
    [BEHAVIORS]: {
        [behaviorId: string]: {
            items: string
        }
    }
    [VARIANTS]: {}
    [TRANSFORMATIONS]: {}
    [TRANSITIONS]: {}
}

export type PropertyType =
    'designQuery'
    | 'propertyQuery'
    | 'dataQuery'
    | 'connectionQuery'
    | 'behaviorQuery'
    | 'layoutQuery'
    | 'breakpointsQuery'
    | 'transformationQuery'
    | 'transitionQuery'
export type Constants = typeof constants

export interface Component {
    [ID]: string
    [TEMPLATE_COMP_ID]: string
    [LAYOUT]: Partial<Layout>
    [TYPE]: string
    [COMPONENT_TYPE]: string
    [DATA_QUERY]: string
    [PROPERTY_QUERY]: string
    [CONNECTION_QUERY]: string
    [BEHAVIOR_QUERY]: string
    [DESIGN_QUERY]: string
    components: string[]
    [PARENT]: string
    [META_DATA]: Partial<ComponentMetaData>
    [STYLE_ID]: string
    [SKIN]: string
    [LAYOUT_QUERY]: string
    [BREAKPOINTS_QUERY]: string
    [TRANSFORMATION_QUERY]: string
    [TRANSITION_QUERY]: string
}

export interface Structure {
    [id: string]: Partial<Component>
}
