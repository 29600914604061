export const constants = {
    VIEWPORT_ENTER_ACTION: 'viewportEnter',
    VIEWPORT_LEAVE_ACTION: 'viewportLeave',
    VIEWPORT_ACTION_NS: 'ViewportAction'
}

const actionHandledCompCache = new WeakMap()

export const functionLibrary = {
    updateActionHandlers: ({next, previous}, registry) => {
        Object.keys(previous).forEach(id => {
            const data = previous[id]

            if (data) {
                const actionHandledMap = actionHandledCompCache.get(data.actions)

                if (actionHandledMap && id in actionHandledMap) {
                    delete actionHandledMap[id]
                }

                registry.unregister(constants.VIEWPORT_ACTION_NS, id)
            }
        })

        Object.keys(next).forEach(id => {
            const data = next[id]

            if (data) {
                const {handler} = data

                registry.register(constants.VIEWPORT_ACTION_NS, id, handler)
            }
        })
    },

    handleViewportAction: (handleAction, viewportActions, {visible}, compId) => {
        if (!actionHandledCompCache.has(viewportActions)) {
            actionHandledCompCache.set(viewportActions, {})
        }

        const actionHandledMap = actionHandledCompCache.get(viewportActions)
        const shouldHandle = compId in actionHandledMap ? actionHandledMap[compId] !== visible : visible

        const actionType = visible ? constants.VIEWPORT_ENTER_ACTION : constants.VIEWPORT_LEAVE_ACTION
        const actionToHandle = viewportActions[actionType]

        if (shouldHandle) {
            actionHandledMap[compId] = visible
        }

        if (actionToHandle && shouldHandle) {
            handleAction(actionToHandle, {action: actionType})
        }
    }
}
