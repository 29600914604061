import {aspectNames} from 'common-types'

export const CSS_CONTAINER = 'CSS_CONTAINER'

export interface CSSStore {
    pagesCss: {
        [id: string]: string
    }
}

const name = aspectNames.CSSAspect

const functionLibrary = {}

const defaultModel: CSSStore = {
    pagesCss: {}
}

export {name, functionLibrary, defaultModel}
