import React, {Children, cloneElement} from 'react'
import PropTypes from 'prop-types'
import {siteConstants} from 'santa-core-utils'
import {santaTypesDefinitions} from 'santa-components'
import ModesTransitionGroupSantaTypeDefinitions from './ModesTrasitionGroupSantaTypeDefinitions'
import forwardRefHOC from 'santa-renderer/src/utils/forwardRefHOC'
import _ from 'lodash'

function modesTransitionGroupHOCFunc(WrappedComponent) {
    class ModesTransitionGroupHOC extends React.Component {
        static compType = 'wysiwyg.viewer.components.BoltHoverBox'
        static santaTypesDefinitions = ModesTransitionGroupSantaTypeDefinitions
        static displayName = `ModesTransitionGroup(${WrappedComponent.displayName})`

        constructor(props) {
            super(props)

            this.state = {
                activeMode: 'DEFAULT',
                children: [],
                added: [],
                removed: [],
                unchanged: [],
                transitioning: [],
                cachedMeasures: [],
                measuresForTransition: [],
                className: 'default'
            }
        }

        static getDerivedStateFromProps(nextProps, state) {
            const {
                activeMode: prevMode,
                children: prevChildren,
                cachedMeasures: prevMeasures,
                transitioning,
                className
            } = state

            const {
                compActiveMode: activeMode,
                children: nextChildren = [],
                modeChildrenMeasures: nextModeMeasures = {},
                meshParams = {}
            } = nextProps

            // Support both mesh and anchors
            const nextMeasures = meshParams.components || nextModeMeasures || prevMeasures

            // Clone current mode children for caching and prevent the clones from updating
            const children = Children.map(nextChildren, child => cloneElement(child, {
                className: `${child.props.className || ''} transitioning-comp`,
                shouldComponentUpdate: false
            }))

            // Get mesh params of transitioning components from cache
            const transitioningMeasures = _.filter(prevMeasures, param => _.find(transitioning, ['props.id', param.id]))
            // Get only measures (or mesh params) of non-transitioning components from nextProps and add the transitioning params from cache
            const modeChildrenMeasures = [...transitioningMeasures, ..._.differenceBy(nextMeasures, transitioningMeasures, 'id')]
            // If activeMode not changed clear stuff and exit
            if (_.get(activeMode, 'type') === prevMode) {
                return {
                    added: [],
                    removed: [],
                    unchanged: [],
                    children,
                    cachedMeasures: modeChildrenMeasures
                }
            }

            // all components in current mode but not in previous mode
            const added = _.differenceBy(children, prevChildren, 'props.id')
            // all components in previous mode but not in current mode
            const removed = _.differenceBy(prevChildren, children, 'props.id')
            // all components present in both modes
            const unchanged = _.intersectionBy(children, prevChildren, 'props.id')
            // Cache removed components mesh params, ignoring transitioning components
            const removedMeshParams = _.filter(
                prevMeasures, param => _.find(removed, ['props.id', param.id]) && !_.find(transitioning, ['props.id', param.id])
            )
            // Combine removed and next
            const cachedMeasures = [...removedMeshParams, ...modeChildrenMeasures]
            // Mode change transitions use the previous layout as the animation 'from' params
            const measuresForTransition = _.keyBy(prevMeasures, 'id')
            // There are specific css rules for transition mode
            const newClassName = className === 'hover' && 'hoverOut' || className === 'default' && 'hoverIn' || className

            return {
                activeMode: _.get(activeMode, 'type'),
                children,
                added,
                removed,
                unchanged,
                cachedMeasures,
                measuresForTransition,
                className: newClassName
            }
        }
        componentWillUnmount() {
            if (_.get(this.props.compActiveMode, 'type') === siteConstants.COMP_MODES_TYPES.HOVER) {
                this.props.deactivateModeById(this.props.id, this.props.rootId, this.props.compActiveMode.modeId)
            }
        }
        componentDidUpdate() {
            const {removed, added, unchanged, transitioning, measuresForTransition} = this.state

            // If we passed through props change
            if (removed.length || added.length || unchanged.length) {
                const removedIds = _.map(removed, 'props.id')
                const addedIds = _.map(added, 'props.id')
                const unchangedIds = _.map(unchanged, 'props.id')

                // Add changes to transitioning list while keeping current transitioning
                const nextTransitioning = [..._.differenceBy([...removed, ...unchanged, ...added], transitioning, 'props.id'), ...transitioning]

                this.props.handleModesInOutBehaviors({removedIds, addedIds}, () => {
                    // Should change to hover\default class when animation ends
                    this.setState({transitioning: [], className: this.state.activeMode === 'HOVER' ? 'hover' : 'default'})
                })
                this.props.handleModesTransitionBehaviors(unchangedIds, measuresForTransition, this.props.layout.fixedPosition, () => {
                    this.setState({transitioning: [], className: this.state.activeMode === 'HOVER' ? 'hover' : 'default'})
                })

                this.setState({
                    added: [],
                    removed: [],
                    unchanged: [],
                    transitioning: nextTransitioning
                })
            }
        }

        render() {
            const {removed, transitioning, className} = this.state
            const {meshParams, forwardedRef, ...props} = _.omit(this.props, [
                'handleModesInOutBehaviors',
                'handleModesTransitionBehaviors',
                'modeChildrenMeasures',
                'compActiveMode'
            ])

            // Get only non transitioning children
            const nextChildren = _.differenceBy(Children.toArray(this.props.children), transitioning, 'props.id')
            const removedChildren = _.differenceBy(removed, transitioning, 'props.id')
            // If we are in mesh, get mesh params
            const newMeshParams = _.isEmpty(meshParams) ? {} : getNewMeshParams(this.state, meshParams)

            return (
                <WrappedComponent {...props} className={className} meshParams={newMeshParams} ref={forwardedRef}>
                    {[...removedChildren, ...transitioning, ...nextChildren]}
                </WrappedComponent>
            )
        }
    }

    const wrappedCompProptypes = _.pick(WrappedComponent.propTypes, ['deactivateModeById'])

    ModesTransitionGroupHOC.propTypes = {
        ...wrappedCompProptypes,
        children: PropTypes.node,
        meshParams: santaTypesDefinitions.Component.meshParams,
        layout: santaTypesDefinitions.Component.layout,
        handleModesInOutBehaviors: ModesTransitionGroupSantaTypeDefinitions.handleModesInOutBehaviors,
        handleModesTransitionBehaviors: ModesTransitionGroupSantaTypeDefinitions.handleModesTransitionBehaviors,
        modeChildrenMeasures: ModesTransitionGroupSantaTypeDefinitions.modeChildrenMeasures,
        compActiveMode: ModesTransitionGroupSantaTypeDefinitions.compActiveMode,
        forwardRef: PropTypes.func
    }

    return ModesTransitionGroupHOC
}

function getNewMeshParams({removed, transitioning, children, cachedMeasures}, meshParams) {
    // Only transitioning mesh params
    const transitioningMeshParams = _.filter(cachedMeasures, param => _.find(transitioning, ['props.id', param.id]))
    // Only removed but not transitioning mesh params
    const removedMeshParams = _.filter(cachedMeasures, param =>
        _.find(removed, ['props.id', param.id]) && !_.find(children, ['props.id', param.id]) && !_.find(transitioning, ['props.id', param.id])
    )
    // Only new and not transitioning mesh params
    const nonTransitioningMeshParams = _.differenceBy(meshParams.components, transitioningMeshParams, 'id')
    // Extend modeChildrenMeasures with removed mesh params
    return {
        ...meshParams,
        components: [
            ...removedMeshParams,
            ...transitioningMeshParams,
            ...nonTransitioningMeshParams
        ]
    }
}


const modesTransitionGroupHOC = forwardRefHOC(modesTransitionGroupHOCFunc)

export {modesTransitionGroupHOC}


