import _ from 'lodash'
import {siteConstants} from 'santa-core-utils'
import {NULL_RETURN_VALUE, QUAB_COMP, COMP_TYPES} from '../constants'
import {bi, common} from 'tpaComponents'
import {navigationHandlersFunctionLibrary} from './navigationHandlers'
import {siteMembersHandlersFunctionLibrary} from './siteMembersHandlers'
import {SOURCES} from 'bolt-main/src/aspects/seo/seo.sources'
import {tpaWarmup} from 'warmupUtils' //eslint-disable-line import/no-unresolved
import reactDOM from 'react-dom'
import $ from 'zepto'
import {wixUrlParser} from 'warmupUtils' //eslint-disable-line import/no-unresolved

const getDataContent = data => {
    if (!data || !data.content) {
        return null
    }

    try {
        return JSON.parse(data.content)
    } catch (ex) {
        return null
    }
}

const isPercentValue = value => _.isString(value) && /^[0-9]+%$/.test(value)

const shouldAllowResizeWindow = (comp, compType) => {
    const allowedTypes = [
        COMP_TYPES.TPA_GLUED_WIDGET,
        COMP_TYPES.TPA_POPUP,
        COMP_TYPES.TPA_MODAL
    ]
    return comp.resizeWindow && _.includes(allowedTypes, compType)
}

export const tpaHandlersFunctionLibrary = {
    resolveHandler: () => Promise.resolve(),
    revalidateSession: (generateNewAppInstance, applicationId) => new Promise(resolve => {
        generateNewAppInstance(applicationId, instance => resolve(instance ? {instance} : {
            error: {
                message: 'Error getting new instance from server'
            }
        }))
    }),
    tpaScrollSiteBy: (scrollSiteByFn, x, y) => new Promise(resolve =>
        scrollSiteByFn(x, y, () => {
            resolve()
        })
    ),
    tpaScrollSiteTo: (scrollSiteToFn, x, y) => new Promise(resolve => {
        scrollSiteToFn(x, y, () => resolve())
    }),
    tpaAnimate: (animateFn, siteContainer, x, y) => new Promise(resolve => {
        const duration = 1
        const delay = 0
        animateFn('BaseScroll', siteContainer, duration, delay, {
            x,
            y,
            callbacks: {
                onComplete: () => resolve()
            }
        })
    }),
    isComponentRegisteredOnQuickBarBehavior: (compId, compAppDefinitionId, actionsAndBehaviors) => {
        const actionName = compAppDefinitionId
        const matchingActions = _(actionsAndBehaviors)
            .filter({action: {sourceId: QUAB_COMP.ID, name: actionName}})
            .filter({behavior: {targetId: compId}})
            .filter({behavior: {name: QUAB_COMP.BEHAVIOR_METHOD}})
            .value()
        return !_.isEmpty(matchingActions)
    },
    resizeWindow: (comp, data, compType) => new Promise(resolve => {
        if (shouldAllowResizeWindow(comp, compType)) {
            const width = !isPercentValue(data.width) ? parseFloat(data.width) : data.width
            const height = !isPercentValue(data.height) ? parseFloat(data.height) : data.height
            comp.resizeWindow(width, height, resolve)
        }
    }),
    tpaEnterFullScreenMode: (comp, setSiteRootHiddenStateFn, enterFullScreenModeFn) => new Promise(resolve => {
        enterFullScreenModeFn()
        setSiteRootHiddenStateFn(true)
        comp.enterFullScreen(resolve)
    }),
    tpaExitFullScreenMode: (comp, setSiteRootHiddenStateFn, exitFullScreenModeFn) => new Promise(resolve => {
        exitFullScreenModeFn()
        setSiteRootHiddenStateFn(false)
        comp.exitFullScreen(resolve)
    }),
    parsePublicData: (data, keys, scope, multiKeys = false) => {
        let result
        const parsedData = getDataContent(data)
        if (parsedData) {
            result = _.pick(parsedData, keys)
        }
        if (_.isEmpty(result)) {
            const prefix = multiKeys ? 'keys' : 'key'
            return {error: {message: `${prefix} ${keys} not found in ${scope} scope`}}
        }
        const extraKeys = _.difference(keys, _.keys(parsedData))
        if (_.size(extraKeys) > 0) {
            const prefix = multiKeys ? 'keys' : 'key'
            return {error: {message: `${prefix} ${extraKeys} not found in ${scope} scope`}}
        }

        return result
    },
    getPublicData: data => data && getDataContent(data),
    typeof: v => typeof v,
    invokeComponentMethod: (comp, method, ...args) => {
        if (_.isFunction(comp[method])) {
            return comp[method](...args)
        }
        return undefined
    },
    registerTPAReLayout: (comp, newHeight, ...args) => {
        const currentHeight = _.get(comp, 'state.height')
        if (currentHeight !== newHeight) {
            return comp.registerReLayout(...args)
        } 
        return undefined
    },
    tpaReportBI: (reportBIFn, reportDef, params) => reportBIFn(reportDef, params),
    tpaNavigateToPage: (navigateToPage, targetPageId, noTransition, anchorDataId, onNavigateComplete) => { //eslint-disable-line consistent-return
        const navInfo = {pageId: targetPageId}
        if (noTransition) {
            navInfo.transition = 'none'
        }
        if (anchorDataId) {
            navInfo.anchorData = anchorDataId
        }
        navigateToPage(navInfo)
        if (onNavigateComplete) {
            return onNavigateComplete()
        }
    },
    tpaNavigateToAnchor: (scrollToAnchor, anchorCompId, anchorCompData, pageId) => {
        if (anchorCompId) {
            if (anchorCompId === siteConstants.PAGE_ANCHORS.TOP_ANCHOR_COMP_ID) {
                scrollToAnchor(siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID)
            } else if (anchorCompId === siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_COMP_ID) {
                scrollToAnchor(siteConstants.PAGE_ANCHORS.BOTTOM_ANCHOR_ID)
            } else if (anchorCompData && anchorCompData.metaData.pageId === pageId) {
                scrollToAnchor(anchorCompData.id)
            } else {
                return {
                    error: {
                        message: `anchor with id "${anchorCompId}" was not found on the current page.`
                    }
                }
            }
        }
        return undefined
    },
    tpaWaitForContextReady: (isPlatformInitialized, isContextReady) => {
        const promise = new Promise(resolve => {
            if (isPlatformInitialized) {
                if (isContextReady) {
                    resolve({})
                }
            } else {
                resolve({
                    error: true
                })
            }
        })
        return {
            promise,
            waitForPromiseToBeResolved: true // this will cause the handler to keep watching for state changes until the promise is resolved
        }
    },
    parseOrDefault: (stringifiedState, defaultValue) => {
        try {
            return JSON.parse(stringifiedState)
        } catch (e) {
            return defaultValue
        }
    },
    handleAppStateChanged: (comp, parsedState = {}, {primaryPageId, scrollToAnchor, navigateToPage, getRenderInfo}, pushStateToSection) => {
        switch (parsedState.cmd) {
            case 'zoom':
                comp.processImageZoom(parsedState)
                break
            case 'itemClicked':
                comp.processItemClicked(parsedState)
                break
            case 'itemChanged':
                comp.processItemChanged(parsedState)
                break
            case 'componentReady':
                comp.setComponentInIframeReady()
                break
            case 'navigateToDynamicPage':
                const renderInfo = getRenderInfo()
                const linkData = parsedState.args[0]
                const url = wixUrlParser.getUrl(renderInfo, linkData)
                const navigationInfo = wixUrlParser.parseUrl(renderInfo, url)
                if (parsedState.args[1]) {
                    navigationInfo.anchorData = parsedState.args[1]
                }
                navigateToPage(navigationInfo)
                break
            case 'navigateToAnchor': {
                const [navigatePageId, anchorData] = parsedState.args
                if (primaryPageId === navigatePageId) {
                    scrollToAnchor(anchorData)
                } else {
                    navigateToPage({
                        pageId: navigatePageId,
                        pageAdditionalData: null,
                        anchorData
                    })
                }
                break
            }
            default:
                pushStateToSection()
                break
        }
    },
    reportAppStateChangedBiEvent: (biData, reportBI, appData, widgetId, compId) => {
        const time = biData.getTime()

        const eventParams = {
            compId,
            pageId: biData.getPageId(),
            pageNo: biData.getPageNumber(),
            loadingTime: time.loadingTime,
            totalLoadingTime: time.totalLoadingTime
        }

        if (_.get(appData, 'isWixTPA')) {
            eventParams.widgetId = widgetId
            eventParams.appDefinitionId = _.get(appData, 'appDefinitionId')
        } else {
            eventParams.externalWidgetId = widgetId
            eventParams.externalAppDefinitionId = _.get(appData, 'appDefinitionId')
        }

        reportBI(bi.events.APP_STATE_CHANGED, eventParams)
    },
    tpaReportActivity: (activity, hostName, mainPagePath, userSession, runtimeFunctions) => {
        const setUserSession = userSessionToken => {
            runtimeFunctions.updateSessionInfoProperty('svSession', userSessionToken)
        }
        const getReturnValueFromResponse = params => ({
            response: {
                activityId: params.response.activityId,
                contactId: params.response.contactId
            },
            status: params.status
        })
        const onSuccess = response => {
            if (response.userSessionToken) {
                setUserSession(response.userSessionToken)
            }
            return getReturnValueFromResponse({
                status: true,
                response
            })
        }
        const onError = response => {
            throw getReturnValueFromResponse({
                status: false,
                response: {
                    status: response.status,
                    statusText: response.statusText,
                    responseText: response.responseText
                }
            })
        }

        return runtimeFunctions.reportActivity(activity).then(onSuccess, onError)
    },
    sdkVersionIsAtLeast: common.utils.sdkVersionIsAtLeast,
    resolveStyleData: (compStyle, styleData, loadPageFn, pageId, isPageRequestCompleted, errorObj) => {
        const promise = new Promise(resolve => {
            if (compStyle) {
                resolve(styleData)
            } else if (!pageId || isPageRequestCompleted) {
                resolve(errorObj)
            } else {
                loadPageFn(pageId)
            }
        })
        return {
            promise,
            waitForPromiseToBeResolved: true
        }
    },
    tpaGetPageTopAnchorName: pageId => common.utils.Constants.TOP_PAGE_ANCHOR_PREFIX + pageId,
    reportPerformanceBiEvent: (biEvent, additionalBIData, appData, widgetId, params) => tpaWarmup.reportPerformanceBiEvent(
        biEvent,
        additionalBIData.reportBI,
        additionalBIData.biData,
        additionalBIData.id,
        additionalBIData.isTpaRenderedInSsr,
        appData,
        widgetId,
        params
    ),
    getBIEventByKey: key => tpaWarmup.events[key],
    getDecodedInstanceValue: (appData, key) => {
        if (!appData) {
            return NULL_RETURN_VALUE
        }
        const instancePartToDecode = appData.instance.replace(/^[^.]+./, '')
        const vendorProductId = _.get(JSON.parse(atob(instancePartToDecode)), key, NULL_RETURN_VALUE)
        return vendorProductId === null ? NULL_RETURN_VALUE : vendorProductId
    },
    getRectAndOffset: (reactComp, headerHeight) => {
        const result = {
            offsets: {x: 0, y: 0},
            rect: {left: 0, right: 0, top: 0, bottom: 0, height: 0, width: 0}
        }
        const node = reactDOM.findDOMNode(reactComp) //eslint-disable-line react/no-find-dom-node
        if (node) {
            const offset = $(node).offset()
            result.offsets = {
                x: offset.left,
                y: offset.top - headerHeight
            }
            const clientRect = node.getBoundingClientRect()
            result.rect = _(clientRect)
                .pick(['left', 'right', 'top', 'bottom', 'height', 'width'])
                .mapValues(value => Math.floor(value))
                .value()
            result.rect.top -= headerHeight
        }

        return result
    },
    tpaSetRunTimePageName: getData => {
        const {setCompData, currentPageId, pageName, currentPageName} = getData()
        if (currentPageName !== pageName) {
            setCompData(currentPageId, {title: pageName})
        }
    },
    tpaSetRunTimePageTitle: getData => {
        const {isSuperApp, tpaPageTitle, setRunTimePageTitle} = getData()
        if (isSuperApp) {
            setRunTimePageTitle({value: tpaPageTitle, source: SOURCES.TPA, forceOverride: true})
        }
    },
    tpaSetRunTimePageDescription: getData => {
        const {setRunTimePageDescription, description} = getData()
        setRunTimePageDescription({value: description, source: SOURCES.TPA})
    },
    ...navigationHandlersFunctionLibrary,
    ...siteMembersHandlersFunctionLibrary
}

