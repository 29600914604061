import _ from 'lodash'

function serialize(params, obj, traditional, scope) {
    let type
    const array = _.isArray(obj)
    const hash = _.isObject(obj)
    _.forEach(obj, (value, key) => {
        type = typeof value
        if (scope) {
            key = traditional ?
                scope :
                `${scope}[${hash || type === 'object' || type === 'array' ? key : ''}]`
        }
        if (!scope && array) {
            params.add(value.name, value.value)
        } else if (type === 'array' || !traditional && type === 'object') {
            serialize(params, value, traditional, key)
        } else {
            params.add(key, value)
        }
    })
}

function formUrlencoded(obj, traditional) {
    const params = []
    params.add = (key, value) => {
        if (_.isFunction(value)) {
            value = value()
        }
        if (value === null) {
            value = ''
        }
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
    serialize(params, obj, traditional)
    return params.join('&').replace(/%20/g, '+')
}

export function serializeFetchData(data, asForm, headers, allowErrors = false) {
    if (headers) {
        data = data || {}
        data.headers = {...data.headers || {}, ...headers}
    }
    if (data && _.isObject(data.body)) {
        const extraHeaders = {
            accept: 'application/json',
            ...{'Content-Type': asForm ? 'application/x-www-form-urlencoded' : 'application/json'}
        }
        const body = asForm ?
            formUrlencoded(data.body) :
            JSON.stringify(data.body)
        return {...data, headers: {...data.headers || {}, ...extraHeaders}, body, allowErrors}
    }
    return data
}
