import _ from 'lodash'
import * as englishTranslation from 'santa-langs/src/main/resources/santa-viewer/bundles/_generated/santa_viewer_en.json'
export const name = 'TranslationsLoaderAspect'
export const defaultModel = {
    translations: {
        en: englishTranslation
    }
}

export const functionLibrary = {
    logOnError: (lang, e) => console.log(`failed loading translation for ${lang}: ${e}`),
    getTranslationAllKeys: (translations, feature, lang, defaultValue) =>
        _.get(translations, [lang, feature]) || _.get(translations, ['en', feature]) || defaultValue
}
