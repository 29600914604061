import {withActions} from '../withActions'
import constants from './constants'
import * as _ from 'lodash'

export const name = 'ViewportAspect'

const {
    HANDLERS_NS,
    INTERSECTIONS,
    VIEWPORT_STATE,
    VIEWPORT_STATE_REQUESTS,
    DEFAULT_THRESHOLD,
    HANDLER_KEY_DELIMITER
} = constants

export const defaultModel = {
    [HANDLERS_NS]: {},
    [INTERSECTIONS]: {},
    [VIEWPORT_STATE]: {},
    [VIEWPORT_STATE_REQUESTS]: {}
}

const privateObservers = new WeakMap()
const cleanup = new WeakMap()

function getObserver(window, setViewportIntersection, overrides) {
    const options = {
        root: null, // document
        rootMargin: '0px',
        threshold: DEFAULT_THRESHOLD,
        ...overrides
    }

    const observerKey = getObserverKey(options.threshold)

    function handler(entries) {
        const intersections = entries.map(({isIntersecting, intersectionRatio, intersectionRect, target}) => ({
            isIntersecting,
            ratio: intersectionRatio,
            rect: intersectionRect,
            id: target.id,
            observer: observerKey
        }))

        intersections.forEach(intersection =>
            setViewportIntersection(getHandlerKey(observerKey, intersection.id), intersection)
        )
    }

    return new window.IntersectionObserver(handler, options)
}

function getHandlerKey(namespace, id) {
    return `${id}${HANDLER_KEY_DELIMITER}${namespace}`
}

function getObserverKey(threshold) {
    return threshold.sort().join('|')
}

function getDefaultStateKey(id) {
    return getHandlerKey(getObserverKey(DEFAULT_THRESHOLD), id)
}

const register = withActions(({setViewportHandler}, namespace, id, handler) => {
    if (typeof handler === 'function') {
        handler = {handler}
    }

    if (handler.threshold) {
        if (!Array.isArray(handler.threshold)) {
            handler.threshold = [handler.threshold]
        }
    } else if (!handler.in && !handler.out) {
        handler.threshold = DEFAULT_THRESHOLD
    } else {
        handler.threshold = [handler.in, handler.out].filter(x => x)
    }

    handler.id = id
    handler.namespace = namespace
    handler.observer = getObserverKey(handler.threshold)

    return setViewportHandler(getHandlerKey(namespace, id), handler)
})

const unregister = withActions(({setViewportHandler, setViewportState, setViewportIntersection}, namespace, id, threshold) => {
    if (!threshold) {
        threshold = DEFAULT_THRESHOLD
    } else if (!Array.isArray(threshold)) {
        threshold = [threshold]
    }

    setViewportHandler(getHandlerKey(namespace, id))

    const stateKey = getHandlerKey(getObserverKey(threshold), id)

    setViewportState(stateKey) // delete component's state from store
    setViewportIntersection(stateKey) // delete component's intersection from store
})

export const functionLibrary = {
    register,

    unregister,

    checkComponentViewportState: withActions(({setViewportStateRequest}, id) => new Promise(resolve => setViewportStateRequest(id, resolve))),

    resolveViewportStateRequest: withActions(({setViewportStateRequest}, resolve, compRef, id, state) => {
        if (compRef && !state) {
            return
        }

        const {isIntersecting} = compRef ? state : {isIntersecting: false}
        resolve({in: isIntersecting})
        setViewportStateRequest(id)
    }),

    updateObservers: withActions(({setViewportIntersection}, {next, previous}, windowObject) => {
        if (!windowObject) {
            return
        }

        if (!privateObservers.has(windowObject)) {
            privateObservers.set(windowObject, {})
        }

        const observers = privateObservers.get(windowObject)

        previous.forEach(({ref}) => {
            const unobserve = ref && cleanup.get(ref)
            if (unobserve) {
                cleanup.delete(ref)
                unobserve()
            }
        })

        next.forEach(({id, ref, threshold}) => {
            threshold = threshold || DEFAULT_THRESHOLD

            const rootRef = _.get(ref, ['refs', ''])
            const element = rootRef instanceof Element ? rootRef : windowObject.document.getElementById(id)
            const observerKey = getObserverKey(threshold)
            let observer = observers[observerKey]

            if (!observer) {
                observer = getObserver(windowObject, setViewportIntersection, {threshold})
                observers[observerKey] = observer
            }

            cleanup.set(ref, () => observer.unobserve(element))
            observer.observe(element)
        })
    }),

    setViewportState: withActions(({setViewportState}, entry) =>
        setViewportState(
            getHandlerKey(entry.observer, entry.id),
            JSON.parse(JSON.stringify(entry)))
    ),

    invokeHandler: (handlers, entry, compId) => {
        if (handlers) {
            handlers.forEach(handler => {
                handler = handler.handler || handler
                handler(entry, compId)
            })
        }
    },

    getDefaultStateKey
}
