import React from 'react'
import PropTypes from 'prop-types'
import {guidUtils} from 'santa-core-utils'
import _ from 'lodash'

class Effect extends React.Component {
    constructor(props) {
        super(props)
        this.uniqueId = guidUtils.getUniqueId('ooi-animation-')
    }

    componentDidMount() {
        this.registerAnimation()
    }

    registerAnimation() {
        const {addRuntimeCompAnimationStub, compId} = this.props

        let name
        switch (this.props.in) {
            case 'Roll':
                name = 'Reveal'
                break
            case 'Puff':
                name = 'DropIn'
                break
            case 'Zoom':
                name = 'ExpandIn'
                break
            default:
                name = `${this.props.in || 'Fade'}In`
        }

        const {
            power,
            direction,
            duration,
            delay,
            distance,
            angle,
            cycles} = this.props.params

        const durationInSeconds = _.isNumber(duration) ? duration / 1000 : duration

        addRuntimeCompAnimationStub(this.uniqueId, {
            action: 'screenIn',
            root: compId,
            power,
            direction,
            distance,
            angle,
            cycles,
            duration: durationInSeconds,
            delay,
            name
        })
    }

    render() {
        const childElement = this.props.children
        if (Array.isArray(childElement)) {
            console.error('Cannot apply animation Effect on multiple child elements')
            return childElement
        }
        return (
            <React.Fragment> {
                React.cloneElement(childElement, {
                    'data-ooi-animate': true,
                    id: this.uniqueId
                }, childElement.props.children)
            }
            </React.Fragment>
        )
    }
}

Effect.propTypes = {
    in: PropTypes.string,
    out: PropTypes.string,
    params: PropTypes.object.isRequired,
    addRuntimeCompAnimationStub: PropTypes.func.isRequired,
    compId: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
}

export {
    Effect
}
