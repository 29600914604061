const _ = require('lodash')

export const TRANSPARENT_BASE64_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

function defaultToEmptyImage(data) {
    if (!_.isString(data) && !data.uri) {
        return _.assign({}, data, {uri: TRANSPARENT_BASE64_PIXEL})
    }
    return data
}

export function resolve(data) {
    switch (data.type) {
        case 'Image':
            return defaultToEmptyImage(data)
        case 'ImageList':
            return _.assign({}, data, {items: _.map(data.items, defaultToEmptyImage)})
        default:
            return data
    }
}
