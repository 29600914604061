import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const name = 'NavigationBaseAspect'

export const defaultModel = {
    dyingPrimaryPageInfo: null,
    isduringTransition: false,
    navigationInteractedStarted: false,
    nextNavigationInfoForSM: null,
    prevPages: [],
    renderedPages: [],
    navigationCallbacks: [],
    navigationErrorCallbacks: []
}


const isChangingRoute = (currentNavInfos, nextNavInfos) => {
    const nextPrimary = nextNavInfos.primaryPage
    const currentPrimary = currentNavInfos.primaryPage
    return nextPrimary.pageId !== currentPrimary.pageId ||
        nextPrimary.innerRoute !== currentPrimary.innerRoute ||
        nextPrimary.tpaInnerRoute !== currentPrimary.tpaInnerRoute ||
        !_.isEqual(nextPrimary.routerDefinition, currentPrimary.routerDefinition)
}

const shouldReportBI = (currentNavInfos, navInfo) => {
    if (navInfo.isPopup) {
        return false
    }

    return isChangingRoute(currentNavInfos, {primaryPage: navInfo})
}

const isNavigatingInImageZoom = (currentNavInfos, nextNavInfos) =>
    currentNavInfos.primaryPage.imageZoom && nextNavInfos.primaryPage.imageZoom

const runNavigationCallbacks = (actions, navigationCallbacks) => {
    _.forEach(navigationCallbacks, cb => cb())
    actions.setNavigationCallbacks(0, navigationCallbacks.length)
}

export const functionLibrary = {
    startNavigationProcess: withActions((actions, currentNavInfos, setToHostInstance, isAllowedBySM, getPrevState, reportPageNavigation, reportPageNavigationInteractionStarted, navInfo) => {
        const isDynamicPageRouting = !navInfo.pageId && navInfo.routerDefinition

        if (navInfo.isPopup || isAllowedBySM(navInfo.pageId) || isDynamicPageRouting) {
            actions.setNextNavigationForSM(null)
            const prevState = getPrevState()
            const currentPrevPages = prevState.prevPages
            if (!_.includes(currentPrevPages, prevState.primaryPageId)) {
                actions.savePageInPrevPages(currentPrevPages.length, 0, prevState.primaryPageId)
            }

            if (shouldReportBI(currentNavInfos, navInfo)) {
                reportPageNavigation(navInfo.pageId)
            }

            if (currentPrevPages[0] !== 'masterPage') {
                actions.savePageInPrevPages(0, 0, 'masterPage')
            }

            let nextNavInfos = navInfo.isPopup ?
                _.assign({}, currentNavInfos, {popupPage: navInfo}) :
                {primaryPage: navInfo}

            if (navInfo.isKeepingRoots) {
                nextNavInfos = _.assign({}, nextNavInfos, {popupPage: navInfo})
            }

            if (navInfo.pageId) {
                actions.setNavigationInteractedStarted(true)
                reportPageNavigationInteractionStarted()
            }

            if (isDynamicPageRouting) {
                actions.setNavigationInteractedStarted(true)
            }

            setToHostInstance(_.cloneDeep(nextNavInfos))
        } else {
            actions.setNextNavigationForSM(navInfo)
        }
    }),

    actualNavigate: withActions((actions, currentNavInfos, navigationCallbacks, nextNavInfos) => {
        if (isNavigatingInImageZoom(currentNavInfos, nextNavInfos) && !nextNavInfos.primaryPage.pageItemAdditionalData) {
            nextNavInfos.primaryPage.pageItemAdditionalData = currentNavInfos.primaryPage.pageItemAdditionalData
        }

        actions.setNextNavigationForSM(null)
        actions.setRootNavigationInfos(nextNavInfos)

        if (isChangingRoute(currentNavInfos, nextNavInfos)) {
            actions.setDyingPrimaryPageInfo(currentNavInfos.primaryPage)
            actions.setIsDuringTransition(true)
        } else {
            actions.setNavigationInteractedStarted(false)
            runNavigationCallbacks(actions, navigationCallbacks)
        }
    }),

    endTransition: withActions((actions, navigationCallbacks) => {
        actions.setDyingPrimaryPageInfo(null)
        actions.setNavigationInteractedStarted(false)
        actions.setIsDuringTransition(false)
        runNavigationCallbacks(actions, navigationCallbacks)
    }),

    addPageToRenderedPages: withActions((actions, dataRequirementsState, pageId, getCurrentRenderedPages) => {
        if (!dataRequirementsState) {
            return
        }
        const currentRenderedPages = getCurrentRenderedPages()
        if (!_.includes(currentRenderedPages, pageId)) {
            actions.savePageInRenderedPages(currentRenderedPages.length, 0, pageId)
        }
    }),

    resetPrevAndRenderedPages: withActions((actions, primaryPageId) => {
        actions.setPrevPages([])
        actions.setRenderedPages([primaryPageId])
    }),

    registerNavigationCallbacks: withActions(({setNavigationCallbacks}, navigationCallbacks, cb) => {
        setNavigationCallbacks(navigationCallbacks.length, 0, cb)
    }),

    registerToNavigationError: withActions(({setNavigationErrorCallbacks}, navigationErrorCallbacks, cb) => {
        if (_.isFunction(cb)) {
            navigationErrorCallbacks.push(cb)
            setNavigationErrorCallbacks(navigationErrorCallbacks)
        }
    }),
    runNavigationCallbacks: withActions(runNavigationCallbacks)
}
