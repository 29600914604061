import {guidUtils, svgUtils} from 'santa-core-utils'
import * as mediaItemUtils from 'santa-platform-utils/dist/cjs/mediaItemUtils/mediaItemUtils'
import wixDomSanitizer from 'wix-dom-sanitizer'

// DON'T deconstruct `svgStringToStoreData` here because we override it in SSR and that will break that override
const {SOURCE_TYPES, EMPTY_SVG_ID, EMPTY_SHAPE} = svgUtils
const {nameUUIDFromString: toGUID} = guidUtils

const urlMatcher = /^https?:\/\//
const inlineMatcher = /^\s*<(\?xml|svg)/i

function getSourceType(mediaSrc) {
    const {value, isValid} = mediaSrc

    if (!isValid) {
        return SOURCE_TYPES.EMPTY
    }

    if (value) {
        if (urlMatcher.test(value)) {
            return SOURCE_TYPES.URL
        }
        if (inlineMatcher.test(value)) {
            return SOURCE_TYPES.INLINE
        }
        if (mediaItemUtils.isValidMediaItemUri(value, mediaItemUtils.types.VECTOR)) {
            return SOURCE_TYPES.WIX_MEDIA_ID
        }
    }
    return SOURCE_TYPES.EMPTY
}

function handleSvgMediaItemUrl(mediaSrc) {
    const parsed = mediaItemUtils.parseMediaItemUri(mediaSrc.value)
    return {
        svgId: parsed.mediaId,
        title: parsed.title,
        mediaSrc
    }
}

/**
 * Pass svg string through HTML Sanitizer
 * @param svgString
 * @returns {*}
 */
function parseAndCleanSvg(svgString) {
    const {svg, error} = wixDomSanitizer.sanitizeSVG(svgString)

    if (!svg) {
        console.warn(error) //eslint-disable-line no-console
        return EMPTY_SHAPE
    }
    return svg
}

function handleExternalSvg(mediaSrc, compId, {svgShapes, setSvgShape, runtimeDal, fetchFn}) {
    const svgId = toGUID(mediaSrc.value)
    if (!svgShapes[svgId]) {
        const onError = () => {
            const {mediaSrc: lastSetSource = {}} = runtimeDal.getCompData(compId)
            if (mediaSrc.value === lastSetSource.value) {
                runtimeDal.setCompData(compId, {svgId: EMPTY_SVG_ID, mediaSrc})
            }
        }

        const onSuccess = svgString => {
            try {
                setSvgShape(svgId, svgUtils.svgStringToStoreData(parseAndCleanSvg(svgString)))
                const {mediaSrc: lastSetSource = {}} = runtimeDal.getCompData(compId)
                if (mediaSrc.value === lastSetSource.value) {
                    runtimeDal.setCompData(compId, {svgId, mediaSrc})
                }
            } catch (e) {
                onError(e)
            }
        }

        fetchFn(mediaSrc.value, {
            method: 'GET',
            headers: {'content-type': 'text/plain'},
            mode: 'cors'
        }, 'text', onSuccess, onError)

        return {mediaSrc}
    }

    return {svgId, mediaSrc}
}

function handleInlineSvg(mediaSrc, {svgShapes, setSvgShape}) {
    const svgId = toGUID(mediaSrc.value)
    if (!svgShapes[svgId]) {
        setSvgShape(svgId, svgUtils.svgStringToStoreData(parseAndCleanSvg(mediaSrc.value)))
    }

    return {svgId, mediaSrc}
}

export const resolve = (data, resolverGeneralInfo, {compId}) => {
    if (data.type !== 'VectorImage' || !data.hasOwnProperty('mediaSrc')) {
        return data
    }
    const {mediaSrc} = data

    const sourceType = getSourceType(mediaSrc)

    switch (sourceType) {
        case SOURCE_TYPES.WIX_MEDIA_ID:
            return handleSvgMediaItemUrl(mediaSrc)
        case SOURCE_TYPES.URL:
            return handleExternalSvg(mediaSrc, compId, resolverGeneralInfo)
        case SOURCE_TYPES.INLINE:
            return handleInlineSvg(mediaSrc, resolverGeneralInfo)
        case SOURCE_TYPES.EMPTY:
        default:
            return {svgId: EMPTY_SVG_ID, mediaSrc}
    }
}
