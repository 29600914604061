import _ from 'lodash'

const name = 'AccessibilityAspect'
const defaultModel = {
    prefersReducedMotion: false
}
const functionLibrary = {
    createAriaLabelTranslationFunc: allTranslationsForLanguage =>
        (feature, key, defaultValue) => _.get(allTranslationsForLanguage, [feature, key], defaultValue)
}

function init({setPrefersReducedMotion}, {initialData: {getSystemPrefersReducedMotion}}) {
    const reducedMotion = getSystemPrefersReducedMotion()
    setPrefersReducedMotion(reducedMotion)
}

export {name, functionLibrary, defaultModel, init}
