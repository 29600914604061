const _ = require('lodash')

export const functionLibrary = {
    setQuerySiteMap: (hostApi_setQuerySiteMap, navInfo, resolve, reject) => {
        hostApi_setQuerySiteMap(_.uniqueId('siteMap'), {navInfo, resolve, reject})
    },
    setHostPlatformContextCounter: (hostApi_setPlatformContextCounter, getNewContextCounter) => {
        const newCounter = getNewContextCounter()
        hostApi_setPlatformContextCounter(newCounter)
    }
}
