import _ from 'lodash'
import {utils} from 'santa-renderer'
import EventEmitter from 'events'

const {hostRuntimeUtils} = utils

const getRuntimeInit = BoltRuntimes => {
    const boltInit = (modelFactory, model, batchingStrategy, renderingFunctionsLib, hostApi, logger, functionLibraryOverrides, exceptionHandlingApi) => {
        const eventsManager = new EventEmitter()
        const boltFunctionLib = hostRuntimeUtils.buildFuncLib(eventsManager, BoltRuntimes, {}, renderingFunctionsLib, hostApi, functionLibraryOverrides)

        const boltDefaultModel = hostRuntimeUtils.buildDefaultModel(BoltRuntimes)

        const instance = modelFactory(_.merge({}, boltDefaultModel, model), boltFunctionLib, batchingStrategy)

        return hostRuntimeUtils.initInstance(instance, eventsManager, BoltRuntimes, logger, exceptionHandlingApi)
    }

    const runtimeModulesForValidation = [{functionLibrary: hostRuntimeUtils.buildFuncLib(new EventEmitter())}, ...BoltRuntimes]

    return {
        boltInit,

        // TODO remove when we have namespaces
        runtimeModulesForValidation
    }
}

export {
    getRuntimeInit
}
