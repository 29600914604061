'use strict'
const _ = require('lodash')
const {hashUtils} = require('santa-core-utils')

const isSkinRepresentation = splitted => splitted.length > 1

const shortenStyleId = styleIdOrSkin => {
    const splitted = (styleIdOrSkin || '').split('.')
    if (isSkinRepresentation(splitted)) {
        const fullSkinNameHashValue = hashUtils.SHA256.b64_sha256(styleIdOrSkin)
        const result = fullSkinNameHashValue.substr(fullSkinNameHashValue.length - 5) + splitted.pop()
        return `s_${result.replace(/[^a-z]/gi, '')}`
    }
    return styleIdOrSkin
}

module.exports = {
    functionLibrary: {
        getStyleData: (stylesMap, defaultSkinName, styleId, skinOverride) => {
            const styleData = stylesMap[styleId]
            const skinName = skinOverride || defaultSkinName
            return _.defaults({}, styleData, {skin: skinName})
        },
        isComponentPinned: (pinnedComponents, compId) => {
            let component = pinnedComponents[compId]
            let isPinned = false
            while (component && !isPinned) {
                isPinned = component.isPinned
                component = pinnedComponents[component.parent]
            }
            return isPinned
        },
        shortenStyleId
    }
}
