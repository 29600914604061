import {noop} from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const name = 'CompScrollHandlerAspect'

export const defaultModel = {}

export const functionLibrary = {
    setCompToScrollTo: withActions((actions, compId, callbacks) => {
        const {setCompIdToScrollTo, setScrollToCompCallback} = actions
        if (callbacks) {
            setScrollToCompCallback(callbacks)
        }
        setCompIdToScrollTo(compId)
    }),
    scrollToComp: withActions((actions, scrollSiteTo, getCompYPosition, getScrollCallback = noop) => {
        const {setCompIdToScrollTo, setScrollToCompCallback} = actions
        scrollSiteTo(0, getCompYPosition(), getScrollCallback())
        setScrollToCompCallback(undefined)
        setCompIdToScrollTo(undefined)
    }),
    updateAnchorIdToScrollTo: withActions(({setAnchorDataIdToScrollTo}, {anchorData: anchorDataId}) => {
        if (anchorDataId) {
            setAnchorDataIdToScrollTo(anchorDataId)
        }
    }),
    scrollToAnchorNotDuringTransition: withActions(({setAnchorDataIdToScrollTo}, checkIsDuringPageTransition, scrollToAnchor) => {
        if (!checkIsDuringPageTransition()) {
            scrollToAnchor()
        }
        setAnchorDataIdToScrollTo(undefined)
    }),
    updateScrollToAnchorDataAndCallback: withActions((actions, anchorDataId, progressCallback) => {
        const {setAnchorDataIdToScrollTo, setScrollToAnchorProgressCallback} = actions
        setAnchorDataIdToScrollTo(undefined)
        setScrollToAnchorProgressCallback(undefined)
        if (progressCallback) {
            setScrollToAnchorProgressCallback(progressCallback)
        }
        if (anchorDataId) {
            setAnchorDataIdToScrollTo(anchorDataId)
        }
    })
}
