import {withActions} from 'carmi-host-extensions'

const _ = require('lodash')
const {getScriptDescription} = require('./externalScriptRegistery') // TODO use externalScriptRegistery once santa's version is updated in bolt (moved the original from core to coreUtils BOLT-248

export const name = 'ExternalAspect'
export const defaultModel = {
    scriptsMap: {},
    callbacksMap: {},
    externalScripts: {},
    externalWixCodeScripts: {},
    externalScriptsRenderedInSsr: {}
}

const getStructure = scriptName => ({
    id: scriptName,
    key: scriptName,
    componentType: 'wysiwyg.viewer.components.ScriptClassForBolt'
})

export const functionLibrary = {
    unsubscribe: withActions((aspectActions, scriptName) => {
        aspectActions.setScriptData(scriptName, undefined)
    }),
    loadScript: withActions((aspectActions, scriptsMap, scriptName, callback, context) => {
        const callbackData = {
            callback: callback || _.noop,
            context
        }

        // const scriptInstance = scriptsMap[scriptName]
        // if (scriptInstance) {
        //     aspectActions.setScriptCallback(scriptName, scriptInstance.props.scriptData.callbacks.length, callbackData)
        // } else {
        const structure = getStructure(scriptName)
        aspectActions.setScriptData(scriptName, {
            structure,
            props: {
                scriptData: {
                    script: getScriptDescription(scriptName, context)
                    // callbacks: [callbackData]
                }
            }
        })

        aspectActions.saveCallback(_.uniqueId(), {scriptName, callbackData})
        // }
    }),
    loadExternalScript: withActions((aspectActions, src, hostedInCorvid = false) => {
        if (!src) {
            return
        }
        const action = hostedInCorvid ? aspectActions.setExternalWixCodeScript : aspectActions.setExternalScript
        action(src, true)
    }),
    renderExternalScript: withActions((aspectActions, windowObject, script) => {
        const scriptTag = windowObject.document.createElement('script')
        scriptTag.src = script
        scriptTag.type = 'text/javascript'
        scriptTag.charset = 'utf-8'
        scriptTag.async = 'true'
        windowObject.document.head.appendChild(scriptTag)
    })
}
