import * as _ from 'lodash'
export const name = 'ExecuteAnimationAspect'
import {withActions} from '../withActions'

export const defaultModel = {
    compsWithRuntimeAnimations: {}
}
const CLEAR_PROPS = 'clip,clipPath,webkitClipPath,opacity,transform'

const getAnimationParams = (animator, behavior) => {
    const animationDefaultParams = _.get(_.find(animator.viewerDefaults, {name: behavior.name}), 'params')
    return _.defaults({}, behavior.params, animationDefaultParams)
}

export const functionLibrary = {
    executeAnimationBehavior: withActions((aspectActions, {behavior, shouldAnimate, element, targetId, animator}) => {
        if (!animator || !element) {
            // If things are not yet rendered, skip and mark animation as NOT executed
            return false
        }

        if (!shouldAnimate) {
            // Skip the animation and just run the callback, but mark animation as executed
            _.invoke(behavior, 'callback')
            return true
        }

        // Execute animation, attach the passed callback to the animation complete callback and mark the animation as executed
        const {name: animationName} = behavior
        const {duration, delay, clear = true, ...params} = getAnimationParams(animator, behavior)
        const callbacks = {onComplete: () => _.invoke(behavior, 'callback')}

        const sequence = animator.sequence({callbacks})
        sequence.add(animator.animate(animationName, element, duration, delay, params), 0)

        // for $w.animate(), we want to control when the animations are cleared from platform code
        // TODO: tests will be added when animate() gets to corvid worker master
        if (clear) {
            sequence.add(animator.animate('BaseClear', element, 0, 0, {props: CLEAR_PROPS, immediateRender: false}))
        }
        // Add comp id to the list of animated components so we could clear them if needed (editor for example)
        aspectActions.setComp(targetId, true)

        return true
    }),
    // Reset the transform on comps that were animated by code and clear the list
    clearCompsWithRuntimeAnimations: withActions((aspectActions, animator, elementsToClear) => {
        elementsToClear.forEach(element => {
            const sequence = animator.sequence()
            sequence.add(animator.animate('ClearSequence', element, 0, 0))
            sequence.add(animator.animate('BaseClear', element, 0, 0, {props: CLEAR_PROPS, immediateRender: false}))
        })
        aspectActions.setCompList({})
    })
}
