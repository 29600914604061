'use strict'
const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {generateStyleNodeProps} = require('../utils/styleNodeUtils')
const shouldComponentUpdateHOC = require('./ShouldComponentUpdateHOC')

const ComponentStyleNode = props => {
    const {cssData, rootId = ''} = props

    return (
        <React.Fragment>
            {_.map(cssData, (css, innerStyleId) => <style {...generateStyleNodeProps(innerStyleId, css, rootId)}/>)}
        </React.Fragment>
    )
}

ComponentStyleNode.propTypes = {
    cssData: PropTypes.object.isRequired,
    rootId: PropTypes.string
}

module.exports = shouldComponentUpdateHOC(ComponentStyleNode)
