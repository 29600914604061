import {BEATS} from '../../bi/constants'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import _ from 'lodash'
import * as warmupUtils from 'warmupUtils' //eslint-disable-line import/no-unresolved


const DEFAULT_SAMPLE_RATIO = 10
const PAGE_INTERACTIVE_DELAY = 100

const IS_BROWSER = typeof window !== 'undefined'

function init(aspectActions, 
    {initialData: {windowObject, biData, reportBi, bsiManager, webBiLoggerFactory, setBiLogger, getPageNumber}}) {
    const setBiService = windowObject && _.get(windowObject, ['customElementsPackage', 'setServices', 'bi'])
    if (setBiService) {
        setBiService(biData, reportBi)
    }

    if (webBiLoggerFactory) {
        const businessLogger = webBiLoggerFactory()
        if (bsiManager) {
            businessLogger.updateDefaults({
                bsi: () => {
                    const pn = getPageNumber()
                    return bsiManager.getBsi(pn)
                }
            })
        }
        setBiLogger(businessLogger.logger())
    }
}

/**
 * @param reportDef
 * @returns {boolean}
 */
const passedCallLimit = reportDef => {
    if (!reportDef) {
        return false
    }
    reportDef.callCount = reportDef.callCount || 0
    reportDef.callCount++
    return reportDef.callLimit && reportDef.callCount > reportDef.callLimit
}

const shouldSuppressBI = currentUrl => _.get(currentUrl, 'query.suppressbi') === 'true'

/**
 *
 * @param sampleRatio
 * @param currentUrl
 * @param wbs
 * @param isDebugMode
 * @returns {boolean}
 */
const shouldIncludeInSampleRatio = (sampleRatio, currentUrl, wbs, isDebugMode) => {
    const sampleRatioState = _.get(currentUrl, 'query.sampleratio')
    isDebugMode = !!isDebugMode
    if (isDebugMode && sampleRatioState !== 'force' || sampleRatioState === 'none' || !sampleRatio) {
        return true
    }

    if (wbs.viewerSessionId) {
        if (_.isUndefined(wbs.coin)) {
            wbs.coin = parseInt(wbs.viewerSessionId, 16)
        }
        return wbs.coin % sampleRatio === 0
    }
    // return Math.floor(wbs.random * sampleRatio) === 0 // TODO fix this
    return true
}


const isSiteInSampleRatio = (reportDef, isWixSite, currentUrl, wbs, isDebugMode) => {
    let sampleRatio = DEFAULT_SAMPLE_RATIO
    if (reportDef) {
        if (isWixSite && 'wixSiteSampleRatio' in reportDef) {
            sampleRatio = reportDef.wixSiteSampleRatio
        } else if ('sampleRatio' in reportDef) {
            sampleRatio = reportDef.sampleRatio
        } else if ('errorCode' in reportDef || reportDef.endpoint === 'editor') {
            sampleRatio = 0
        }
    }
    if (sampleRatio && sampleRatio >= 1) {
        return shouldIncludeInSampleRatio(sampleRatio, currentUrl, wbs, isDebugMode)
    }
    return true
}

/**
 * @param reportDef
 * @param isWixSite
 * @param currentUrl
 * @param wixBiSession
 * @param isDebugMode
 * @returns {boolean}
 */
const shouldSendReport = (reportDef, isWixSite, currentUrl, wixBiSession, isDebugMode) => !shouldSuppressBI(currentUrl) &&
    !passedCallLimit(reportDef) &&
    isSiteInSampleRatio(reportDef, isWixSite, currentUrl, wixBiSession, isDebugMode)

const viewerSessionId = {}
const MAJOR_VER = IS_BROWSER && !window.clientSideRender ? 4 : 3
const IS_DS = IS_BROWSER && window.queryUtil && window.queryUtil.isParameterTrue('isEdited')
const IS_PREVIEW = IS_BROWSER && typeof window.publicModel === 'undefined'

const IS_QA_DEBUG = IS_BROWSER && window.queryUtil && window.queryUtil.isParameterTrue('isqa')
const MAX_BI_CALLS_KEPT = 1000
const extractSantaVersion = santaBase => {
    const sourceMatches = santaBase && santaBase.match(/([\d\.]+)\/?$/)
    return sourceMatches && sourceMatches[1] || ''
}

const getParamsFromSite = (siteId, currentViewerSessionId, serverName, metaSiteId, santaBase, isMobileView) => {
    viewerSessionId[siteId] = viewerSessionId[siteId] || currentViewerSessionId || warmupUtils.guidUtils.getGUID() // FIXME(ssr-guid) - needs to be taken from siteData
    const server = serverName ? _.head(serverName.split('.')) : ''
    const siteParams = {
        site_id: siteId,
        msid: metaSiteId,
        majorVer: MAJOR_VER,
        ver: extractSantaVersion(santaBase),
        server,
        viewMode: isMobileView ? 'MOBILE' : 'DESKTOP'
    }
    if (!IS_DS) {
        siteParams.vsi = viewerSessionId[siteId]
    }
    return siteParams
}

const getVisitorId = (siteId, wixBiSession) => viewerSessionId[siteId] || wixBiSession && wixBiSession.viewerSessionId

const errorSeverityMap = {
    recoverable: 10,
    warning: 20,
    error: 30,
    fatal: 40
}

const errorMap = {
    errorName: 'errn',
    errorCode: 'errc',
    errc: 'errc',
    src: 'src',
    severity: 'sev',
    sev: 'sev',
    packageName: 'errscp'
}

function getErrorSeverity(severity) {
    return typeof severity === 'string' ?
        errorSeverityMap[severity] :
        severity
}

const extractDefaultErrorDefParams = (reportDef, params, visitorId) => {
    const paramsToReturn = {}
    if (reportDef.src === 44 || reportDef.src === 42 && visitorId) { // eslint-disable-line no-mixed-operators
        paramsToReturn.visitor_id = visitorId
    }

    const dsOrigin = _.get(params, 'dsOrigin')
    if (dsOrigin) {
        paramsToReturn.dsOrigin = dsOrigin
        if (params.esi) {
            paramsToReturn.esi = params.esi
        }
    }

    return _.assign(paramsToReturn, {
        errn: reportDef.errorName,
        evid: 10,
        sev: getErrorSeverity(reportDef.severity),
        cat: IS_DS ? 1 : 2,
        iss: 1,
        ut: warmupUtils.cookieUtils.getCookie('userType')
    })
}


const extractReportParamsAccordingToMap = (reportDef, reportMap) => _.transform(reportDef, (accum, val, key) => {
    const mapped = reportMap[key]
    if (mapped) {
        accum[mapped] = val
    }
}, {})


const sanitizePIIForBi = paramToSanitize => {
    if (_.isString(paramToSanitize)) {
        return warmupUtils.biLoggerSanitizer.sanitizePII(paramToSanitize)
    }
    return paramToSanitize
}

const encodeString = v => typeof v === 'string' ? encodeURIComponent(v) : v

const extractAdditionalParams = (reportDefParams, params) => {
    const additionalParams =
        _.isArray(reportDefParams) && _.pick(params, reportDefParams) ||
        _.isObject(reportDefParams) && _.mapValues(reportDefParams, v => params[v]) ||
        params

    return _(additionalParams)
        .mapValues(sanitizePIIForBi)
        .mapValues(encodeString)
        .value()
}

const getTrancateDescription = params => {
    if (_.has(params, 'description')) {
        return {desc: JSON.stringify(params.description).slice(0, 512)}
    }
    return undefined
}

const extractErrorParams = (reportDef, params, visitorId) => _.merge(
    {
        src: 44,
        sev: 30,
        errn: 'error_name_not_found'
    },
    extractReportParamsAccordingToMap(reportDef, errorMap),
    extractDefaultErrorDefParams(reportDef, params, visitorId),
    extractAdditionalParams(reportDef.params, params),
    getTrancateDescription(params)
)

const eventMap = {
    eventId: 'evid',
    evid: 'evid',
    src: 'src',
    ds_origin: 'ds_origin',
    viewerName: 'viewerName',
    viewerVersion: 'viewerVersion',
    total_js_heap_size: 'total_js_heap_size',
    used_js_heap_size: 'used_js_heap_size',
    js_heap_size_limit: 'js_heap_size_limit'
}

const extractEventParams = (reportDef, params) => _.merge({src: 42},
    extractReportParamsAccordingToMap(reportDef, eventMap),
    extractAdditionalParams(reportDef.params, params))

const extractParams = (reportType, reportDef, params, siteId, currentViewerSessionId, serverName, wixBiSession, metaSiteId, santaBase, isMobileView) => {
    let resultParams
    const paramsFromSite = getParamsFromSite(siteId, currentViewerSessionId, serverName, metaSiteId, santaBase, isMobileView)

    switch (reportType) {
        case 'error':
            const visitorId = getVisitorId(siteId, wixBiSession)
            resultParams = extractErrorParams(reportDef, params, visitorId)
            break
        case 'event':
            resultParams = extractEventParams(reportDef, params)
            break
    }

    return _.merge(resultParams, paramsFromSite)
}

const createWixBIOptions = (reportDef, params, biServerUrl, siteId, currentViewerSessionId, serverName, wixBiSession, metaSiteId, santaBase, isMobileView) => {
    const reportType = reportDef.reportType || (reportDef.errorCode || reportDef.errc ? 'error' : 'event')
    const options = {
        biUrl: biServerUrl,
        adapter: reportDef.adapter || reportDef.endpoint || (reportType === 'error' ? 'trg' : 'ugc-viewer'),
        params: extractParams(reportType, reportDef, params, siteId, currentViewerSessionId, serverName, wixBiSession, metaSiteId, santaBase, isMobileView)
    }
    if ('useBeacon' in reportDef) {
        options.useBeacon = reportDef.useBeacon
    }
    return options
}

const defaultOptions = {
    biUrl: '//frog.wixpress.com',
    adapter: '',
    params: {}
}
const getCurrentTimeStamp = (wixBiSession, options) => {
    // TODO: get mainLoaded value through DS and not through parent
    let start = IS_DS && options.adapter === 'editor' ? window.parent.mainLoaded : 0
    start = start || wixBiSession.initialTimestamp || wixBiSession.mainLoaded
    return _.now() - start
}

const logBICallToWindow = url => {
    if (IS_BROWSER) {
        const biCalls = window._biCalls
        if (biCalls) {
            const length = biCalls.push(warmupUtils.urlUtils.parseUrl(url))
            if (length > MAX_BI_CALLS_KEPT) {
                biCalls.splice(0, length >> 2) // Delete a fourth
            }
        }
    }
}

const sendBI = (host, adapter, queryString, useBeacon, wixBiSession) => {
    let url = warmupUtils.urlUtils.joinURL(host, adapter)
    if (queryString) {
        url += `?${queryString}`
    }
    if (wixBiSession.sendBeacon) {
        wixBiSession.sendBeacon(url, useBeacon)
    }
    if (IS_QA_DEBUG && IS_DS) {
        logBICallToWindow(url)
    }
}


const report = (biUrl, options, wixBiSession, biLogger) => {
    if (biUrl) {
        defaultOptions.biUrl = biUrl
    }

    _.defaults(options, defaultOptions)
    const params = _.defaults(options.params, {
        ts: getCurrentTimeStamp(wixBiSession, options),
        rid: wixBiSession.requestId
    })

    if (!biLogger) {
        const useBeacon = options.useBeacon !== false
        const queryString = _.isString(options.queryString) ?
            options.queryString :
            warmupUtils.urlUtils.toQueryString(_.defaults(options.params, {
                ts: getCurrentTimeStamp(wixBiSession, options),
                rid: wixBiSession.requestId
            }))

        return sendBI(options.biUrl, options.adapter, queryString, useBeacon, wixBiSession)
    }

    return biLogger.log(params, {endpoint: options.adapter})
}

const name = 'biAspect'
const defaultModel = {
    pageId: null,
    pageNumber: 1,
    pageLoadStart: _.get(warmupUtils, 'wixBiSession.initialTimestamp') || _.now(),
    networkPageLoadStart: _.get(warmupUtils, 'wixBiSession.initialRequestTimestamp') || warmupUtils.loggingUtils.performance.timeOrigin,
    reportedPageInteractive: false
}

const reportBI = (biLogger, isWixSite, currentUrl, wixBiSession, isDebugMode, biServerUrl, siteId,
                  currentViewerSessionId, serverName, metaSiteId, santaBase, isMobileView,
                  reportDef, params) => {
    if (wixBiSession.forceReportForTests || shouldSendReport(reportDef, isWixSite, currentUrl, wixBiSession, isDebugMode)) {
        const options = createWixBIOptions(reportDef, params, biServerUrl, siteId, currentViewerSessionId, serverName, wixBiSession, metaSiteId, santaBase, isMobileView)

        if (wixBiSession.reportForTests) {
            wixBiSession.reportForTests(biServerUrl, options, wixBiSession)
            return {reported: true}
        }

        const promise = report(biServerUrl, options, wixBiSession, biLogger)
        return {reported: true, promise}
    }

    return {reported: false}
}

const getBsi = (bsiManager, pageNumber) => bsiManager && bsiManager.getBsi(pageNumber)

module.exports = {
    init,
    defaultModel,
    name,
    functionLibrary: {
        reportBI,
        reportInteractive: withActions(({setReportedInteractive}, pageId, pageNumber, bsiManager, reportBeatEvent, layoutCounter) => {
            if (layoutCounter === 0 && pageNumber === 1) {
                const {beatNumber, eventName} = BEATS.INTERACTIVE
                const bsi = getBsi(bsiManager, pageNumber)
                reportBeatEvent(beatNumber, eventName, {pageId, pageNumber, bsi})
                setReportedInteractive(true)
            }
        }),
        reportPageInteractive: withActions(({setPageInteractive}) => {
            setTimeout(() => setPageInteractive(true), PAGE_INTERACTIVE_DELAY)
        }),
        sendPageInteractive: (pageId, pageNumber, bsiManager, reportBeatEvent) => {
            const {beatNumber, eventName} = BEATS.PAGE_INTERACTIVE
            const bsi = getBsi(bsiManager, pageNumber)
            reportBeatEvent(beatNumber, eventName, {pageId, pageNumber, bsi})
            if (IS_PREVIEW) {
                window.parent.postMessage('santaReady', '*')
                window.parent.postMessage('viewerReady', '*')
            }
        },
        reportPageNavigation: withActions(({setPageNumber, setPageLoadStart, setNetworkPageLoadStart}, updateWixBiSessionProperty, nextPageNumber, bsiManager, reportBeatEvent = _.identity, pageId) => {
            const {beatNumber, eventName, isPageEvent} = BEATS.PAGE_NAVIGATION
            const bsi = getBsi(bsiManager, nextPageNumber)
            reportBeatEvent(beatNumber, eventName, {pageId, isPageEvent, pageNumber: nextPageNumber, bsi})

            setPageNumber(nextPageNumber)
            updateWixBiSessionProperty('pn', nextPageNumber)

            const pageLoadStart = _.now()
            updateWixBiSessionProperty('initialTimestamp', pageLoadStart)
            setPageLoadStart(pageLoadStart)

            const networkPageLoadStart = warmupUtils.loggingUtils.performance.timeOrigin
            updateWixBiSessionProperty('initialRequestTimestamp', networkPageLoadStart)
            setNetworkPageLoadStart(networkPageLoadStart)
        }),
        reportPageNavigationDone: (pageNumber, bsiManager, reportBeatEvent = _.identity, pageId) => {
            const {beatNumber, eventName, isPageEvent} = BEATS.PAGE_NAVIGATION_DONE
            const bsi = getBsi(bsiManager, pageNumber)
            reportBeatEvent(beatNumber, eventName, {pageId, isPageEvent, pageNumber, bsi})
        },
        handleBsiActivity(bsiManager, pageNumber) {
            return getBsi(bsiManager, pageNumber) // notifies bsi that an activity occured
        },
        getBITimeObject: (pageLoadStart, pageNumber) => {
            const loadingTime = _.now() - pageLoadStart
            const totalLoadingTime = pageNumber === 1 ? Math.round(warmupUtils.loggingUtils.performance.now()) : loadingTime
            return {
                loadingTime,
                totalLoadingTime
            }
        }
    }
}

